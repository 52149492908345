.w-layout-grid {
	grid-row-gap: 16px;
	grid-column-gap: 16px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.w-form-formradioinput--inputType-custom {
	width: 12px;
	height: 12px;
	border: 1px solid #ccc;
	border-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
	box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
	border-width: 4px;
	border-color: #3898ec;
}

.w-checkbox {
	margin-bottom: 5px;
	padding-left: 20px;
	display: block;
}

.w-checkbox:before {
	content: " ";
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-checkbox:after {
	content: " ";
	clear: both;
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-checkbox-input {
	float: left;
	margin: 4px 0 0 -20px;
	line-height: normal;
}

.w-checkbox-input--inputType-custom {
	width: 12px;
	height: 12px;
	border: 1px solid #ccc;
	border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
	background-color: #3898ec;
	background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
	box-shadow: 0 0 3px 1px #3898ec;
}

.body {
	font-family: Minervamodern;
}

.flowbase-close-card {
	opacity: 0.43;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	padding: 10px 20px 10px 10px;
	transition: all 0.2s ease-out;
	display: flex;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
}

.flowbase-close-card:hover {
	opacity: 1;
}

.flowbase-home-link {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.flowbase-title {
	color: #0f5d4b;
	margin-bottom: 4px;
	font-size: 17px;
	font-weight: 700;
}

.flowbase-product-wrap {
	z-index: 9;
	width: 70%;
	min-height: 40px;
	background-color: #fff;
	border-radius: 5px;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px;
	display: flex;
	position: absolute;
	top: 20%;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.flowbase-title-wrap {
	width: 80%;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-right: 25px;
	display: flex;
}

.notification-bar {
	width: 100%;
	justify-content: center;
	display: flex;
}

.flowbase-icon-wrap {
	width: 60px;
	height: 60px;
	background-color: #007050;
	border-radius: 200px;
	justify-content: center;
	align-items: center;
	margin-right: 25px;
	display: flex;
	position: relative;
}

.flowbase-left-wrap {
	justify-content: center;
	display: flex;
}

.paragraph {
	margin-bottom: 0;
	font-size: 12px;
	line-height: 15px;
}

.login-button-main {
	height: auto;
	outline-offset: -2px;
	color: #fff;
	background-color: #007050;
	background-image: url('/20181016082423/assets/images/lock.svg');
	background-position: 9%;
	background-repeat: no-repeat;
	background-size: 20px;
	border-radius: 100px;
	outline: 2px solid #007050;
	align-items: center;
	padding: 9px 25px 9px 40px;
	font-family: Ubuntu, Helvetica, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: flex;
}

.login-button-main:hover {
	color: #fff;
	background-color: #0f5d4b;
	outline: 3px solid rgba(0, 0, 0, 0);
}

.search-button {
	width: 38px;
	height: 38px;
	background-color: #4f95d0;
	background-image: url('/20181016082423/assets/images/search_wht.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border-radius: 20px;
	margin-left: 0;
	margin-right: 0;
	transition: all 0.45s;
}

.search-button:hover {
	background-color: #2f6ca0;
}

.careers-link {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #000;
	border-radius: 0;
	align-self: auto;
	align-items: center;
	margin-left: 0;
	margin-right: 0.5rem;
	padding-right: 1rem;
	text-decoration: none;
	transition: background-color 0.4s;
	display: flex;
}

.careers-link:hover {
	background-color: rgba(0, 0, 0, 0);
	border-style: none;
	border-color: #000;
}

.main-button {
	justify-content: flex-end;
	align-items: center;
	margin-top: 6px;
	margin-bottom: 6px;
	display: flex;
}

.location-link {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #fff;
	border-color: #fff rgba(33, 33, 33, 0.1);
	border-radius: 0;
	align-items: center;
	margin-right: 1rem;
	padding-right: 1rem;
	text-decoration: none;
	transition: background-color 0.4s;
	display: flex;
}

.location-link:hover {
	background-color: rgba(0, 0, 0, 0);
	border-style: none;
	border-color: #000;
}

.wrapper {
	width: 100%;
	justify-content: flex-end;
	display: flex;
}

.contact-us {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #fff;
	border-color: #fff rgba(33, 33, 33, 0.1);
	border-radius: 0;
	align-items: center;
	margin-right: 1rem;
	padding-right: 1rem;
	text-decoration: none;
	transition: background-color 0.4s;
	display: flex;
}

.contact-us:hover {
	background-color: rgba(0, 0, 0, 0);
	border-style: none;
	border-color: #000;
}

.link-text {
	opacity: 0.75;
	color: #fff;
	letter-spacing: 0;
	text-transform: capitalize;
	border-bottom: 1px rgba(255, 255, 255, 0.35);
	padding-bottom: 5px;
	font-family: Ubuntu, Helvetica, sans-serif;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 0.95rem;
	text-decoration: none;
	transition: opacity 0.3s;
}

.link-text:hover {
	opacity: 1;
	color: #fff;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.top-nav {
	height: auto;
	justify-content: space-between;
	align-items: center;
	padding: 20px 100px;
	display: flex;
}

.search-input-2 {
	height: 38px;
	border-radius: 20px;
	margin-bottom: 0;
	margin-right: -38px;
}

.div-block-21 {
	z-index: 100;
	width: 100%;
	height: auto;
	background-color: #1a1a1a;
	justify-content: flex-end;
	padding: 0 5%;
	display: none;
	position: fixed;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
	overflow: hidden;
}

.search-2 {
	width: 38px;
	height: 38px;
	opacity: 1;
	align-items: center;
	margin: 10px 20px;
	display: flex;
	overflow: hidden;
}

.search-2.hidden {
	display: none;
}

.search-bar {
	z-index: 15;
	width: 60%;
	height: 69%;
	background-color: #fff;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	justify-content: space-between;
	align-items: center;
	padding-left: 1rem;
	padding-right: 1rem;
	display: none;
	position: absolute;
	top: 95px;
	bottom: auto;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.uui-button-row {
	grid-column-gap: 0.75rem;
	grid-row-gap: 0.75rem;
	flex-wrap: wrap;
	align-items: center;
	display: flex;
}

.div-block-119 {
	width: 25px;
	height: 25px;
	cursor: pointer;
	background-image: url('/20181016082423/assets/images/exit-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
}

.uui-button-link-gray-2 {
	grid-column-gap: 0.5rem;
	color: #475467;
	white-space: nowrap;
	background-color: rgba(0, 0, 0, 0);
	justify-content: flex-start;
	align-items: center;
	padding: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-button-link-gray-2:hover {
	color: #344054;
}

.uui-button-link-gray-2.is-button-xsmall {
	font-size: 0.875rem;
}

.uui-navbar02_dropdown-content-left-2 {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	flex: 1;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	padding: 0.5rem 2rem 0 0;
	display: grid;
}

.nav-link {
	height: 50px;
	color: #fff;
	text-transform: uppercase;
	justify-content: center;
	align-items: center;
	padding-right: 16px;
	font-family: minerva-modern, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
	display: flex;
}

.nav-link:hover {
	color: #faa831;
}

.nav-link.w--current {
	color: #000;
}

.uui-navbar02_blog-item {
	border-radius: 0.5rem;
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: max-content;
	grid-auto-columns: 1fr;
	padding: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar02_blog-item:hover {
	background-color: #fff;
}

.uui-button-link {
	grid-column-gap: 0.5rem;
	color: #6941c6;
	white-space: nowrap;
	background-color: rgba(0, 0, 0, 0);
	justify-content: center;
	align-items: center;
	padding: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-button-link:hover {
	color: #53389e;
}

.uui-button-link.is-button-xsmall {
	font-size: 0.875rem;
}

.search-bar-wrap {
	height: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0;
	padding-right: 15px;
	display: flex;
}

.uui-navbar02_blog-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.uui-navbar02_heading-2 {
	color: #1a1a1a;
	letter-spacing: normal;
	text-transform: capitalize;
	cursor: pointer;
	margin-top: 0;
	margin-bottom: 0.25rem;
	padding-left: 0.75rem;
	display: none;
}

.search-input-exit {
	width: 5%;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/exit-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 12px 12px;
	display: block;
}

.search-input-exit.two {
	z-index: 5;
	width: 20px;
	padding: 0 0;
	display: none;
	position: relative;
}

.search {
	justify-content: center;
	align-items: center;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	display: none;
}

.uui-navbar02_dropdown-content-right-2 {
	max-width: 22rem;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex: 1;
	grid-template-rows: max-content auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	padding: 1.25rem;
	display: none;
	position: relative;
}

.uui-navbar02_dropdown-toggle-2 {
	height: 50px;
	color: #475467;
	justify-content: flex-start;
	align-items: center;
	padding-right: 10px;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar02_dropdown-toggle-2:hover {
	color: #344054;
}

.uui-navbar02_dropdown-toggle-2.w--open {
	height: 100%;
	padding-bottom: 20px;
}

.uui-navbar02_dropdown-toggle-2.w--open:hover {
	padding-bottom: 20px;
}

.uui-navbar02_menu-2 {
	clear: none;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: row;
	flex: 1;
	justify-content: flex-end;
	align-items: center;
	font-family: Minervamodern;
	display: flex;
	position: static;
}

.dropdown-link {
	grid-column-gap: 0rem;
	grid-row-gap: 0px;
	color: #1a1a1a;
	border-radius: 0.5rem;
	grid-template-rows: auto;
	grid-template-columns: max-content 1fr;
	grid-auto-columns: 1fr;
	justify-content: flex-start;
	align-items: center;
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
	padding-left: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.dropdown-link:hover {
	background-color: rgba(0, 0, 0, 0);
}

.uui-navbar02_blog-image-wrapper {
	width: 100%;
	border-radius: 0.375rem;
	margin-bottom: 1.5rem;
	padding-top: 55%;
	position: relative;
	overflow: hidden;
}

.text-block-12 {
	color: #fff;
	text-transform: uppercase;
	font-family: minerva-modern, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
}

.text-block-12:hover {
	color: #998868;
}

.brand {
	padding-left: 0;
}

.uui-logo_component-5 {
	width: 100%;
	height: 70px;
	flex-wrap: nowrap;
	display: flex;
}

.uui-navbar02_dropdown-right-overlay-absolute {
	min-width: 100vw;
	background-color: #f9fafb;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: auto;
}

.uui-navbar02_menu-left-2 {
	width: auto;
	grid-column-gap: 20px;
	grid-row-gap: 32px;
	justify-content: space-around;
	align-items: center;
	margin-right: 0;
	display: flex;
}

.uui-navbar02_dropdown-content-wrapper {
	z-index: 1;
	grid-template-rows: max-content;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	display: grid;
	position: relative;
}

.search-input {
	width: 95%;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #000;
	margin-bottom: 0;
	padding: 1.8rem 3.1rem 1.8rem 1rem;
	font-size: 0.85rem;
	font-weight: 300;
	position: relative;
}

.brand-logo {
	width: 100%;
	height: auto;
	flex: none;
}

.login-button-main-mobile {
	height: auto;
	color: #fff;
	background-color: #007050;
	background-image: url('/20181016082423/assets/images/lock.svg');
	background-position: 9%;
	background-repeat: no-repeat;
	background-size: 20px;
	border: 2px #84d5f7;
	border-radius: 100px;
	align-items: center;
	padding: 9px 25px 9px 40px;
	font-family: Ubuntu, Helvetica, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: none;
}

.login-button-main-mobile:hover {
	background-color: #1a1a1a;
}

.uui-navbar02_item-heading-2 {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: uppercase;
	margin-bottom: 0.25rem;
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
}

.uui-navbar02_item-heading-2:hover {
	color: #faa831;
}

.uui-navbar02_item-right {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	font-weight: 400;
	display: flex;
}

.uui-navbar02_dropdown-link-list {
	grid-column-gap: 0px;
	grid-row-gap: 0rem;
	flex-direction: column;
	grid-template-rows: max-content;
	grid-template-columns: 1fr;
	grid-auto-rows: max-content;
	grid-auto-columns: 1fr;
	display: flex;
}

.uui-navbar02_container-2 {
	width: 100%;
	height: auto;
	max-width: none;
	justify-content: space-between;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.uui-navbar02_dropdown-content-2 {
	width: 100%;
	display: flex;
}

.uui-dropdown-icon {
	color: #fff;
	justify-content: center;
	align-items: center;
	display: flex;
}

.uui-navbar02_menu-button-3 {
	padding: 0;
}

.uui-navbar02_dropdown-content-button-wrapper {
	letter-spacing: normal;
	margin-top: 0.75rem;
	font-size: 0.875rem;
}

.uui-text-size-small {
	color: #475467;
	letter-spacing: normal;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 0.875rem;
	line-height: 1.5;
}

.uui-navbar02_dropdown-list-2 {
	box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.uui-navbar02_dropdown-list-2.w--open {
	width: auto;
	max-width: none;
	min-width: auto;
	background-color: rgba(0, 0, 0, 0);
	margin-top: 0;
	padding-top: 5px;
	padding-left: 0%;
	padding-right: 0%;
	position: absolute;
	top: auto;
	bottom: auto;
	left: auto;
	right: auto;
	overflow: hidden;
}

.mega-menu {
	z-index: 2000;
	width: 100%;
	height: 100px;
	min-height: auto;
	background-color: rgba(4, 125, 191, 0.8);
	justify-content: center;
	align-items: center;
	padding-left: 3%;
	padding-right: 3%;
	display: flex;
	position: fixed;
	top: 0;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.uui-navbar02_blog-content {
	flex-direction: column;
	justify-content: flex-start;
	font-weight: 400;
	display: flex;
}

.uui-navbar02_dropdown-blog-item-wrapper {
	flex-direction: column;
	grid-template-rows: auto auto auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	align-items: flex-start;
}

.search-icon {
	width: 35px;
	height: 35px;
	min-height: auto;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border: 0.25px rgba(0, 0, 0, 0);
	border-radius: 5px;
	padding: 0 0.25rem;
	transition: all 0.3s, background-color 0.3s;
}

.search-icon:hover {
	background-color: #faa831;
	background-image: url('/20181016082423/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border-style: none;
	border-color: #fff;
	border-radius: 5px;
}

.invisible-block {
	z-index: 90;
	height: 100px;
	background-color: rgba(0, 0, 0, 0);
	position: absolute;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
}

.slider-container-2 {
	width: 60%;
	justify-content: flex-end;
	align-items: flex-end;
	display: flex;
	overflow: visible;
}

.lynx-block-hero-2 {
	z-index: 10;
	width: 60%;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-top: 140px;
	margin-left: 12%;
	display: flex;
	position: absolute;
	top: 80px;
	left: 0;
}

.icon-quick-link {
	color: #1c3d66;
	margin-top: 0;
	margin-right: 0.5rem;
	padding-right: 0;
	font-family: Open Sans, sans-serif;
	font-size: 0.95rem;
	font-weight: 700;
	line-height: 1.1rem;
	text-decoration: none;
	transition: all 0.25s;
	display: flex;
}

.icon-quick-link:hover {
	background-position: 100% 70%;
}

.icon-wrapper {
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
	border-top: 0 solid rgba(0, 0, 0, 0);
	border-bottom: 0 solid rgba(0, 0, 0, 0);
	border-radius: 0;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 0;
	margin-bottom: 0;
	display: flex;
}

.slide-2 {
	height: 580px;
}

.lynx-paragraph-white-4 {
	color: #fff;
	margin-bottom: 16px;
	font-size: 18px;
	line-height: 165%;
}

.lynx-subtitle-white-3 {
	color: #00c98a;
	letter-spacing: 1.3px;
	-webkit-text-stroke-color: #00e09a;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
	line-height: 140%;
	display: none;
}

.right-arrow-4 {
	height: 50px;
	margin-bottom: 60px;
	display: none;
	bottom: 0%;
	right: 70px;
}

.slide-1 {
	height: 580px;
	background-position: 0%;
	background-size: auto;
}

.slider {
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
}

.mask-5 {
	z-index: 6;
}

.image-home {
	width: 0.75rem;
	height: 0.75rem;
}

.h3-headings {
	max-width: 500px;
	color: #1c3d66;
	text-align: center;
	letter-spacing: 0;
	text-indent: 0;
	text-transform: none;
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	border-right: 5px #3eedb6;
	margin-top: 1rem;
	margin-bottom: 0;
	margin-right: 0;
	padding-right: 0;
	font-family: Open Sans, sans-serif;
	font-size: 1.1rem;
	font-weight: 700;
	line-height: 1rem;
	display: inline-block;
}

.h3-headings.icon-headings {
	width: 80%;
	max-width: none;
	color: #fff;
	text-align: left;
	letter-spacing: 0;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 1.25rem;
	padding-left: 0;
	font-family: minerva-modern, sans-serif;
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 1.5rem;
}

.arrow-link-block {
	width: 2rem;
	height: 2rem;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	margin-top: 4rem;
	display: flex;
	position: static;
	top: auto;
	bottom: 3rem;
	left: auto;
	right: 3rem;
}

.arrow-link-block.quick-links {
	width: 1.15rem;
	height: 1.15rem;
	background-color: #1c3d66;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	margin-top: 0;
	display: flex;
}

.slide-nav-3 {
	z-index: 8;
	height: 50px;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 8%;
	padding-top: 0;
	padding-right: 40px;
	display: flex;
	bottom: 64%;
	left: 0%;
	right: auto;
}

.icon-wrap {
	width: 6rem;
	height: 6rem;
	background-color: #fff;
	border-radius: 200px;
	justify-content: center;
	align-items: center;
	display: flex;
	overflow: hidden;
}

.bold-text-5 {
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
	line-height: 68%;
}

.main-button-apply {
	height: auto;
	color: #fff;
	background-color: #007050;
	border: 2px #84d5f7;
	border-radius: 100px;
	align-items: center;
	padding: 9px 25px;
	font-weight: 600;
	line-height: 20px;
	text-decoration: none;
	transition: all 0.45s;
	display: inline-block;
}

.main-button-apply:hover {
	background-color: #1a1a1a;
}

.main-button-apply.dark-background {
	z-index: 10;
	color: #0f5d4b;
	background-color: #fff;
}

.main-button-apply.dark-background:hover {
	color: #fff;
	background-color: #1a1a1a;
}

.img-hero {
	width: 100%;
	height: 100%;
}

.img-hero._2 {
	background-image: none;
}

.left-arrow-4 {
	height: 50px;
	margin-bottom: 60px;
	display: none;
	bottom: 0%;
	right: 140px;
}

.div-block-81 {
	align-items: center;
	margin-top: 2rem;
	display: none;
}

.hero-div {
	height: 100%;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-template-columns: 1.25fr 1fr;
	grid-auto-columns: 1fr;
	display: block;
}

.bold-text-7 {
	color: #fff;
	font-family: Heebo, sans-serif;
	font-size: 22px;
	font-weight: 300;
	line-height: 27px;
}

.quick-links-wrapper {
	z-index: 7;
	width: 80%;
	height: 200px;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 0;
	padding-bottom: 0;
	padding-left: 12%;
	display: flex;
	position: absolute;
	bottom: -1vw;
}

.icon-img {
	width: 60%;
	height: auto;
	max-width: none;
	opacity: 1;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 0;
	display: block;
}

.icon-link-wrapper {
	width: 100%;
	height: 200px;
	opacity: 1;
	background-color: rgba(255, 255, 255, 0.8);
	border: 1px solid rgba(0, 0, 0, 0);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 0;
	margin-right: 2%;
	padding-top: 1rem;
	padding-bottom: 0;
	padding-right: 0;
	line-height: 25px;
	text-decoration: none;
	display: flex;
}

.lynx-heading-white-4 {
	max-width: 740px;
	color: #fff;
	margin-top: 0;
	margin-bottom: 15px;
	font-family: PT Serif, serif;
	font-size: 38px;
	font-weight: 400;
	line-height: 114%;
}

.lynx-heading-white-4.price {
	font-family: minerva-modern, sans-serif;
	font-size: 60px;
	font-weight: 400;
	line-height: 87%;
}

.promo-slider {
	width: 100%;
	height: 590px;
	background-color: #047dbf;
	justify-content: center;
	align-items: center;
	padding-bottom: 0;
	display: none;
	position: relative;
	top: 130px;
}

.resources-content {
	grid-column-gap: 12px;
	grid-row-gap: 32px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	justify-items: center;
	margin-top: 40px;
	display: grid;
}

.horiz-heading-underline {
	width: 75px;
	height: 2px;
	color: #047dbf;
	background-color: #047dbf;
	margin-bottom: 20px;
}

.text-block {
	max-width: 750px;
	color: #1c3d66;
	text-align: left;
	margin-bottom: 1rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
}

.text-block.centered {
	color: #1d1d1b;
	text-align: center;
	margin-bottom: 1rem;
	font-family: Heebo, sans-serif;
}

.resources-list-link {
	color: #fff;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin-top: 10px;
	margin-right: 0;
	font-family: minerva-modern, sans-serif;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.25rem;
	text-decoration: none;
}

.resources-list-link:hover {
	color: #faa831;
	text-transform: uppercase;
}

.resources-grid {
	width: 80%;
	flex-direction: column;
	margin-top: 0;
	display: flex;
}

.heading-5 {
	color: #047dbf;
	letter-spacing: 0;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 20px;
	font-family: minerva-modern, sans-serif;
	font-size: 50px;
	font-weight: 400;
}

.heading-5.heading-5-inside-pages {
	font-size: 14px;
	font-weight: 700;
}

.resources-list-cb-link {
	width: 290px;
	height: 205px;
	color: #1a1a1a;
	background-color: #047dbf;
	border-radius: 0;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 1.5rem 20px;
	text-decoration: none;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	display: flex;
	box-shadow: 0 6px 20px rgba(13, 104, 83, 0.16);
}

.resources-list-cb-link:hover {
	color: #0f5d4b;
	transform: scale(1.1);
}

.services {
	flex-direction: column;
	justify-content: center;
	margin-bottom: 100px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	padding-left: 5%;
	padding-right: 5%;
	display: none;
	position: relative;
	top: 0;
}

.heading-wrapper {
	flex-direction: column;
	align-items: center;
	display: flex;
}

.resources-content-section {
	z-index: 2;
	width: 100%;
	max-width: 1450px;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	display: flex;
	position: relative;
}

.footer-signoff-link-2 {
	color: #fff;
	letter-spacing: 1px;
	font-family: Heebo, sans-serif;
	font-size: 0.8rem;
	font-weight: 300;
	text-decoration: none;
	transition: color 0.3s;
}

.footer-signoff-link-2:hover {
	color: #faa831;
}

.footer-section {
	width: 100%;
	height: auto;
	background-color: #fff;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 20px;
	padding-left: 0%;
	padding-right: 0%;
	display: flex;
}

.link-block-2 {
	width: auto;
	height: auto;
	margin-right: 20px;
}

.image-9 {
	height: 35px;
	margin-right: 20px;
}

.div-block-17 {
	width: 25%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 20px;
	display: flex;
}

.column-3 {
	height: 100%;
	justify-content: flex-start;
	align-items: center;
	padding-left: 0;
	padding-right: 0;
	font-family: Heebo, sans-serif;
	display: flex;
}

.footer-grip-link {
	width: 133px;
	height: 29px;
	min-width: 0;
	cursor: pointer;
	background-image: url('/20181016082423/assets/images/globalreach_dark.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 130px 29px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	text-decoration: none;
	transition: all 0.2s;
}

.footer-grip-link.white-logo {
	width: 140px;
	background-image: url('/20181016082423/assets/images/globalreach_white.svg');
	background-size: 150px 29px;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 0;
	display: flex;
}

.div-block-15 {
	width: 100%;
	border-top: 0 solid #fff;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	margin-left: 5%;
	margin-right: 5%;
	padding-left: 5%;
	padding-right: 5%;
	display: flex;
}

.image-10 {
	width: 50%;
	max-width: none;
}

.address {
	color: #fff;
	margin-bottom: 0;
	font-weight: 500;
	display: block;
}

.columns {
	width: auto;
	height: auto;
	border-top: 0 solid #fff;
	justify-content: space-between;
	align-items: center;
	margin-top: 0;
	margin-left: 5%;
	margin-right: 5%;
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
}

.footer-signoff-link-wrap {
	display: inline-block;
}

.div-block-16 {
	justify-content: space-between;
	margin-top: 30px;
	margin-bottom: 10px;
	display: flex;
}

.image-8 {
	height: 50px;
	margin-right: 20px;
}

.div-block-18 {
	flex-direction: row;
	justify-content: space-between;
	align-self: auto;
	align-items: flex-end;
	display: flex;
}

.grip-logo-text-box-2 {
	color: #000;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
	font-size: 0.5rem;
	font-weight: 700;
	line-height: 0.5rem;
	text-decoration: none;
}

.grip-logo-text-box-2.webdevby-white {
	width: auto;
	color: #fff;
	letter-spacing: 0;
	font-family: Heebo, sans-serif;
	font-weight: 400;
}

.footer-copyright {
	color: #fff;
	margin-bottom: 0;
	margin-right: 1.5rem;
	font-family: Heebo, sans-serif;
	font-size: 0.9rem;
	font-weight: 300;
	display: inline-block;
}

.footer-copyright h1 {
	display: inline;
	margin: 0px;
	padding: 0px;
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-style: inherit;
	font-size: inherit;
	text-transform: inherit;
	font-weight: inherit;
}

.column-2 {
	height: 100%;
	justify-content: flex-end;
	align-items: center;
	padding-right: 0;
	display: flex;
}

.footer-signoff-links {
	border-bottom: 0 solid #1d1d1b;
	margin-right: 1rem;
	line-height: 1.25rem;
	display: inline-block;
}

.footer-signoff-links:hover {
	color: #fff;
	border-bottom-color: #faa831;
}

.text-block-8 {
	height: auto;
	color: #1d1d1b;
	text-transform: capitalize;
	border-left: 1px solid #047dbf;
	flex-direction: column;
	margin-left: 20px;
	padding-left: 20px;
	font-family: minerva-modern, sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 22px;
	display: flex;
}

.link-2 {
	color: #fff;
	text-decoration: none;
}

.uui-page-padding-11 {
	padding-left: 2rem;
	padding-right: 2rem;
}

.heading-6 {
	color: #047dbf;
	text-transform: uppercase;
	margin-top: 0;
	font-family: minerva-modern, sans-serif;
	font-size: 40px;
	font-weight: 400;
}

.uui-button-icon-8 {
	width: 1.25rem;
	height: 1.25rem;
	min-height: 1.25rem;
	min-width: 1.25rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
}

.uui-testimonial05_slide-nav {
	height: 1.75rem;
	font-size: 0.5rem;
}

.uui-testimonial05_client-image-wrapper {
	margin-top: 2rem;
	margin-bottom: 1rem;
	display: none;
}

.uui-testimonial05_arrow {
	width: 3.5rem;
	height: 3.5rem;
	color: #667085;
	background-color: #fff;
	border: 1px solid #eaecf0;
	border-radius: 100%;
	justify-content: center;
	align-items: center;
	transition: all 0.3s;
	display: flex;
}

.uui-testimonial05_arrow:hover {
	background-color: #f9fafb;
}

.uui-testimonial05_arrow.hide-mobile-landscape {
	color: #667085;
}

.uui-heading-medium-8 {
	color: #101828;
	margin-top: 0;
	margin-bottom: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 2.25rem;
	font-weight: 600;
	line-height: 1.3;
}

.uui-heading-medium-8.text-weight-medium {
	color: #47494c;
	font-family: Heebo, sans-serif;
	font-size: 18px;
	font-weight: 400;
}

.uui-testimonial05_logo-wrapper {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 2rem;
	display: flex;
}

.uui-testimonial05_component {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-bottom: 4rem;
}

.uui-testimonial05_slide {
	padding-left: 4rem;
	padding-right: 4rem;
}

.uui-container-large-11 {
	width: 70%;
	max-width: 80rem;
	margin-left: auto;
	margin-right: auto;
}

.uui-testimonial05_content {
	width: 80%;
	height: 100%;
	max-width: 64rem;
	text-align: center;
	flex-direction: column;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.testimonias-one-by-one {
	background-color: rgba(45, 69, 120, 0.2);
	margin-top: 0;
	padding-top: 2rem;
}

.uui-text-size-medium-9 {
	color: #475467;
	letter-spacing: normal;
	margin-top: 3rem;
	font-family: Heebo, sans-serif;
	font-size: 1rem;
	line-height: 1.5;
}

.uui-testimonial05_client-image {
	width: 4rem;
	height: 4rem;
	min-height: 4rem;
	min-width: 4rem;
	object-fit: cover;
	border-radius: 100%;
}

.uui-padding-vertical-xhuge-11 {
	padding-top: 1rem;
	padding-bottom: 4rem;
}

.uui-heading-tiny-2 {
	color: #101828;
	letter-spacing: normal;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Heebo, sans-serif;
	font-size: 1.125rem;
	font-weight: 600;
	line-height: 1.5;
}

.slide-3 {
	background-position: 50%;
	background-size: cover;
}

.slide-4 {
	background-position: 0 0, 50% 0;
	background-size: auto, cover;
}

.slider-2 {
	height: 100vh;
	top: 95px;
}

.leptos-slider {
	margin-top: -95px;
	display: block;
	position: relative;
}

.heading-7 {
	color: #fff;
	margin-bottom: 20px;
	font-family: minerva-modern, sans-serif;
	font-size: 60px;
	font-weight: 400;
	line-height: 58px;
}

.paragraph-2 {
	font-family: minerva-modern, sans-serif;
}

._3-columns {
	width: 100%;
	height: 100%;
	grid-column-gap: 0px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
}

.text-block-5 {
	color: #fbf9fc;
	text-align: center;
	font-size: 18px;
	font-weight: 400;
	line-height: 1;
	display: inline-block;
}

.main-wrapper {
	width: 100%;
	height: 100vh;
}

.section-home {
	height: 88%;
	max-width: none;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid #080808;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding-top: 0;
	display: flex;
}

.info-container {
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.image-2 {
	height: 24px;
	margin-right: 12px;
}

.column {
	width: auto;
	background-image: linear-gradient(#000, rgba(0, 0, 0, 0) 20%), url('/20181016082423/assets/images/confetti_1confetti.png');
	background-position: 0 0, 50% 0;
	background-repeat: repeat, no-repeat;
	background-size: auto, 80%;
	border-right: 1px solid #161616;
	flex-direction: column;
	justify-content: center;
	margin-right: 0;
	display: flex;
}

.column.last {
	border: 1px #000;
}

.heading {
	color: #fbf9fc;
	text-align: center;
	margin-bottom: 8px;
	font-size: 130px;
}

.heading.followers {
	text-align: center;
}

.heading.tips {
	text-align: center;
	letter-spacing: -5px;
	-webkit-text-fill-color: transparent;
	background-image: linear-gradient(#fbf9fc, #fbf9fc);
	background-position: 0 0;
	-webkit-background-clip: text;
	background-clip: text;
	font-size: 130px;
}

._3six5-link {
	max-width: 250px;
	grid-column-gap: 30px;
	grid-row-gap: 10px;
	color: #000;
	text-align: center;
	background-color: #fff;
	border: 1px solid #7300de;
	border-radius: 25px;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	text-decoration: none;
	display: flex;
	position: fixed;
	bottom: 20px;
	left: 20px;
	box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.05);
}

.spacer-blank {
	width: 100%;
	height: 50px;
	font-size: 1px;
}

.h3 {
	color: #000;
	margin-top: 0;
	margin-bottom: 0;
	font-family: Heebo, sans-serif;
	font-size: 16px;
	font-weight: 300;
	line-height: 18px;
	text-align: center;
}

.promo-counter {
	opacity: 1;
	background-color: rgba(221, 158, 4, 0.2);
}

.logo-small {
	width: 50px;
}

.paragraph-3 {
	color: rgba(24, 24, 24, 0.9);
	margin-bottom: 0;
	font-size: 16px;
	line-height: 1.6;
	display: none;
}

.main-container {
	width: 100%;
	max-width: 1050px;
	flex-direction: column;
	align-items: flex-start;
	display: flex;
}

.spacer-50px {
	width: 50px;
	height: 50px;
}

.h2 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 40px;
	font-weight: 800;
	line-height: 1.3;
}

.counter {
	width: 4ch;
	color: #faa831;
	font-family: minerva-modern, sans-serif;
	font-size: 40px;
	font-weight: 700;
	line-height: 1;
}

.content-wrap-stats {
	width: 100%;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	text-align: left;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 20px;
	flex-direction: column;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	align-items: flex-start;
	justify-items: start;
	padding: 0;
	display: flex;
}

._1-3-grid {
	grid-column-gap: 25px;
	grid-row-gap: 25px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
}

._1-3-grid._50-p {
	grid-column-gap: 50px;
	grid-row-gap: 50px;
	margin-top: 20px;
}

.header-wrap-left {
	width: 100%;
	max-width: 850px;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	text-align: left;
	flex-direction: column;
	align-items: flex-start;
	display: flex;
}

.purple-span {
	color: #7300de;
}

.main-section {
	flex-direction: column;
	align-items: center;
	margin-top: 116px;
	padding: 40px 5% 10px;
	display: flex;
}

.paragraph-3-copy {
	color: rgba(24, 24, 24, 0.9);
	color: rgba(24, 24, 24, 0.9);
	color: rgba(24, 24, 24, 0.9);
	margin-bottom: 0;
	font-size: 16px;
	line-height: 1.6;
	display: none;
}

.plus-icon {
	width: 100%;
	height: 30px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.lynx-block-image {
	flex-direction: row;
	justify-content: center;
	align-items: stretch;
	display: flex;
	position: relative;
}

.lynx-grid-image-left {
	width: 100%;
	grid-column-gap: 26px;
	grid-row-gap: 26px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1.2fr;
	align-items: center;
}

.lynx-icon-check {
	width: 20px;
	height: 20px;
	margin-right: 12px;
}

.lynx-image {
	min-height: 500px;
	object-fit: cover;
	border-radius: 30px;
	box-shadow: 0 60px 60px -15px rgba(31, 32, 34, 0.1);
}

.lynx-heading-2 {
	max-width: 740px;
	color: #1f2022;
	margin-top: 0;
	margin-bottom: 36px;
	font-family: PT Serif, serif;
	font-size: 46px;
	font-weight: 400;
	line-height: 120%;
}

.image-text-6x6 {
	width: 100%;
	max-width: 1080px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-top: 220px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.lynx-block-right {
	padding-left: 10%;
}

.lynx-button {
	color: #fff;
	letter-spacing: 1.3px;
	text-transform: uppercase;
	background-color: #1f2022;
	border-radius: 50px;
	margin-top: 20px;
	padding: 22px 36px;
	font-family: Varela, sans-serif;
	font-size: 13px;
	line-height: 140%;
	text-decoration: none;
	transition: background-color 0.2s;
}

.lynx-button:hover {
	background-color: #171796;
}

.lynx-check {
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 14px;
	display: flex;
}

.lynx-subtitle-2 {
	color: #1f2022;
	letter-spacing: 1.3px;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 140%;
}

.lynx-paragraph {
	max-width: 820px;
	color: #797c83;
	margin-bottom: 36px;
	font-size: 16px;
	line-height: 165%;
}

.lynx-text-check {
	color: #797c83;
	font-size: 18px;
	line-height: 140%;
}

.bold-text {
	font-weight: 600;
}

.text-block-30 {
	color: #fff;
	letter-spacing: 1px;
	text-transform: capitalize;
	font-size: 1.2rem;
	font-weight: 600;
	text-decoration: none;
}

.text-block-30.text-block-color {
	color: #faa831;
	text-transform: uppercase;
	font-family: Heebo, sans-serif;
	font-size: 0.9rem;
	font-weight: 500;
}

.text-block-30.text-block-color:hover {
	color: #faa831;
}

.learn-more-line {
	width: 10px;
	height: 2px;
	color: #fff;
	background-color: #fff;
	position: absolute;
	top: 25px;
	left: 125px;
}

.learn-more-line.line-color {
	background-color: #faa831;
	left: 125px;
}

.heading-1 {
	width: 70%;
	color: #047dbf;
	text-transform: uppercase;
	font-family: minerva-modern, sans-serif;
	font-size: 50px;
	font-weight: 400;
	line-height: 4rem;
}

.heading-1.inside-pages {
	width: 100%;
}

.heading-1.slider-heading {
	width: 100%;
	color: #fff;
	font-size: 60px;
	line-height: 58px;
}

._2-images-wrapper {
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 100px;
	display: flex;
	position: relative;
}

.about-us {
	width: 100%;
	margin-top: 220px;
	overflow: visible;
}

.image-11 {
	width: 100%;
	height: 120%;
	border-radius: 4px;
}

.paragraph-7 {
	width: 70%;
	font-family: Heebo, sans-serif;
	font-size: 1rem;
	line-height: 1.6rem;
}

.link-block-3 {
	width: auto;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid rgba(0, 0, 0, 0);
	border-radius: 4px;
	margin-left: 0;
	padding: 16px 40px 16px 0;
	text-decoration: none;
	position: relative;
}

.link-block-3:hover {
	color: #fff;
}

.link-block-3.secondary-button {
	border-color: rgba(0, 0, 0, 0);
	padding-left: 0;
	background-color: transparent !important;
}

.center-block {
	z-index: 9;
	border: 0 solid rgba(0, 0, 0, 0);
	justify-content: center;
	align-items: center;
	display: flex;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.small-image {
	width: 30%;
	height: 35vw;
	align-items: flex-end;
	display: flex;
	overflow: hidden;
}

.image-26 {
	height: 120%;
	border-radius: 4px;
}

.big-image {
	width: 46%;
	height: 61vw;
	max-height: 1200px;
	object-fit: cover;
	overflow: hidden;
}

.content-wrapper-center {
	width: 80%;
	max-width: none;
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 4px;
	margin-bottom: 140px;
	padding: 40px 80px;
}

.content-wrapper-center.other {
	background-color: rgba(255, 255, 255, 0.8);
}

.text-span {
	text-transform: lowercase;
	padding-top: 10px;
	padding-bottom: 20px;
	font-family: Heebo, sans-serif;
	font-size: 16px;
	font-weight: 300;
	display: inline-block;
}

.text-span.address-text {
	text-transform: none;
}

.text-span.address-text:hover {
	text-decoration: none;
}

.text-span-5.head-office {
	margin-top: -17px !important;
}

.text-span-5.fist-text.email-text {
	margin-top: 17px;
}

.text-span a {
	font-family: Heebo, sans-serif;
	font-size: 16px;
	font-weight: 300;
	text-decoration: none;
	color: #1d1d1b;
}

.text-span:hover {
	text-decoration: underline;
}

.text-span.contact-links {
	text-transform: capitalize;
}

.text-span-2 {
	color: #047dbf;
	padding-bottom: 10px;
	font-size: 17px;
	display: inline-block;
}

.text-span-2.tel-first {
	margin-top: 0;
}

.text-span-3 {
	color: #1d1d1b;
	font-family: Heebo, sans-serif;
	font-size: 16px;
	font-weight: 300;
}

.text-span-4 {
	color: #047dbf;
	margin-bottom: 30px;
	font-family: Heebo, sans-serif;
	font-size: 16px;
	font-weight: 500;
	display: inline-block;
}

.text-span-5 {
	color: #047dbf;
	margin-top: 22px;
	font-size: 17px;
}

.text-span-5.fist-text {
	margin-top: 0;
}

.text-span-5.last-text {
	margin-top: 22px;
}

.heading-10 {
	color: #047dbf;
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
}

.div-block-120 {
	width: 100%;
	border-bottom: 0 solid #1d1d1b;
	flex-direction: row;
	margin-left: 0%;
	padding-left: 8%;
	display: flex;
}

.dividel-footer {
	width: 25%;
	height: 35px;
	border-top: 1px solid #047dbf;
	border-bottom: 0 solid #1d1d1b;
	flex-direction: row;
	margin-left: 0;
	margin-right: auto;
	padding-left: 0%;
	display: flex;
}

.heading-10-copy {
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
	display: none;
}

.portfolio-section {
	background-color: #fcfcfc;
	padding-left: 0%;
	padding-right: 0%;
	position: relative;
	z-index: 1000;
}

.large-card-section {
	width: 100%;
	min-height: auto;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	perspective: 1000px;
	background-color: rgba(0, 0, 0, 0);
	flex-flow: wrap;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	justify-content: center;
	align-items: center;
	margin-top: 60px;
	padding: 3% 10%;
	display: grid;
}

.card-wrapper {
	flex-direction: column;
	align-items: center;
	margin: 0 1rem 2rem;
	line-height: 28px;
	display: flex;
	position: relative;
}

.overflow-wrapper {
	perspective: 1000px;
	overflow: hidden;
}

.large-card-background {
	z-index: 1;
	width: 26rem;
	height: 28rem;
	cursor: pointer;
	object-fit: cover;
	transform-style: preserve-3d;
	flex-direction: column;
	align-items: center;
	margin: 1rem;
	transition: transform 0.4s;
	display: flex;
	position: relative;
	box-shadow: 2px 0 12px rgba(31, 31, 31, 0.22);
}

.large-card-background:hover {
	transform: rotateX(0) rotateY(-20deg) rotateZ(0);
}

.large-card-text-wrap {
	z-index: 2;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	line-height: 28px;
	display: flex;
	position: absolute;
	top: auto;
	bottom: 0.2rem;
	left: auto;
	right: -1rem;
}

.large-card-header {
	color: #047dbf;
	letter-spacing: 1px;
	text-transform: uppercase;
	background-color: #fcfcfc;
	margin-bottom: 0.2rem;
	padding: 0.2rem 0.7rem;
	font-family: minerva-modern, sans-serif;
	font-size: 2rem;
	font-weight: 400;
	line-height: 35px;
	box-shadow: 2px 2px 18px -9px rgba(0, 0, 0, 0.3);
}

.card-subtext {
	color: #1f1f1f;
	text-align: left;
	letter-spacing: 1px;
	text-transform: uppercase;
	cursor: pointer;
	background-color: #fcfcfc;
	margin-top: 5px;
	margin-bottom: 20px;
	padding: 0.2rem 0.6rem;
	font-family: Heebo, sans-serif;
	font-size: 0.7rem;
	font-weight: 500;
	line-height: 1.7;
}

.card-subtext:hover {
	color: #faa831;
}

.large-card-background-3 {
	z-index: 1;
	width: 26rem;
	height: 28rem;
	cursor: pointer;
	object-fit: cover;
	transform-style: preserve-3d;
	flex-direction: column;
	align-items: center;
	margin: 1rem;
	transition: transform 0.4s;
	display: flex;
	position: relative;
	box-shadow: 2px 0 12px rgba(31, 31, 31, 0.22);
}

.large-card-background-3:hover {
	transform: rotateX(0) rotateY(-20deg) rotateZ(0);
}

.large-card-background-2 {
	z-index: 1;
	width: 26rem;
	height: 28rem;
	cursor: pointer;
	object-fit: cover;
	transform-style: preserve-3d;
	flex-direction: column;
	align-items: center;
	margin: 1rem;
	transition: transform 0.4s;
	display: flex;
	position: relative;
	box-shadow: 2px 0 12px rgba(31, 31, 31, 0.22);
}

.large-card-background-2:hover {
	transform: rotateX(0) rotateY(-20deg) rotateZ(0);
}

.image-27 {
	margin-left: 10px;
}

.counter-2 {
	width: 2ch;
	color: #faa831;
	font-family: minerva-modern, sans-serif;
	font-size: 40px;
	font-weight: 700;
	line-height: 1;
}

.counter-3 {
	width: 4.2ch;
	color: #faa831;
	font-family: minerva-modern, sans-serif;
	font-size: 40px;
	font-weight: 700;
	line-height: 1;
}

.nav-link-enquire {
	width: 150px;
	height: 50px;
	color: #fff;
	text-transform: uppercase;
	background-color: #faa831;
	border: 1px solid #faa831;
	justify-content: center;
	align-items: center;
	margin-top: 0;
	margin-bottom: 0;
	padding: 1.75rem 1rem;
	font-family: minerva-modern, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
	display: flex;
}

.nav-link-enquire:hover {
	color: #faa831;
	background-color: #fff;
}

.nav-link-enquire.w--current {
	color: #000;
}

.link-block-4 {
	width: auto;
	height: auto;
	margin-top: 5px;
	margin-bottom: 5px;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-right: 9px;
	text-decoration: none;
	position: relative;
	overflow: visible;
}

.services-2 {
	height: 100vh;
	background-color: #232323;
	background-image: url('/20181016082423/assets/images/hotel-maid-changing-bed-sheet-22.jpg');
	background-position: 0 0;
	background-size: auto;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: none;
	position: relative;
	overflow: hidden;
}

.div-block-121 {
	width: 50%;
	height: 1px;
	text-align: left;
	background-color: #faa831;
	margin-bottom: 10px;
	position: absolute;
	top: auto;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.studio-loft {
	max-height: 550px;
	max-width: none;
	display: block;
	position: absolute;
}

.text-block-6 {
	color: rgba(153, 136, 104, 0.2);
	margin-top: 0;
	padding-top: 0;
	font-family: minerva-modern, sans-serif;
	font-size: 250px;
	line-height: 160px;
	position: absolute;
	top: auto;
	bottom: -1%;
	left: auto;
	right: -1%;
}

.text-block-31 {
	color: #047dbf;
	text-align: center;
	font-family: minerva-modern, sans-serif;
	font-size: 44px;
	line-height: 44px;
	text-decoration: none;
}

.loft-junghwa-dong,
.poblenou-loft {
	max-height: 550px;
	max-width: none;
	display: block;
	position: absolute;
}

.div-block-122 {
	justify-content: center;
	align-items: center;
	display: flex;
}

.gym-loft {
	max-height: 550px;
	max-width: none;
	position: absolute;
}

.div-block-13 {
	width: 80%;
	height: 100%;
}

.tribeca-loft {
	max-height: 550px;
	max-width: none;
	display: block;
	position: absolute;
}

.grid-5 {
	height: 100%;
	grid-column-gap: 0px;
	grid-template-rows: auto;
}

.candy-loft {
	max-height: 550px;
	max-width: none;
	display: block;
	position: absolute;
}

.div-block-14 {
	height: 80%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
}

.div-block-123 {
	width: 400px;
	height: 550px;
	justify-content: center;
	align-items: center;
	display: flex;
	position: relative;
}

.heading-wrapper-2 {
	flex-direction: column;
	align-items: center;
	margin-top: 0;
	margin-bottom: 40px;
	display: flex;
}

.brochure-promo {
	display: none;
}

.promo-container {
	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
}

.div-block-124 {
	width: 355px;
	height: 69px;
	color: #047dbf;
	text-transform: uppercase;
	border: 2px solid #faa831;
	flex-direction: row;
	margin-top: 60px;
	margin-bottom: 60px;
	padding: 20px;
	font-family: minerva-modern, sans-serif;
	font-size: 22px;
	display: flex;
}

.text-block-32 {
	padding-top: 10px;
}

.image-28 {
	width: auto;
	height: 133px;
	position: relative;
	bottom: 12px;
	left: -98px;
	right: 0;
}

.text-block-33 {
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.text-block-33:hover {
	color: #faa831;
}

.icon-wrap-copy {
	width: 6rem;
	height: 6rem;
	width: 6rem;
	height: 6rem;
	width: 6rem;
	height: 6rem;
	background-color: #fff;
	border-radius: 200px;
	justify-content: center;
	align-items: center;
	display: none;
	overflow: hidden;
}

.link-text-2 {
	z-index: 99;
	color: #131313;
	justify-content: center;
	align-items: center;
	padding-top: 0;
	padding-bottom: 0;
	font-size: 1.75rem;
	font-weight: 400;
	line-height: 1.35;
	text-decoration: none;
	display: flex;
	position: relative;
	transform: perspective(725px);
}

.link-text-2:hover {
	text-transform: none;
	align-self: center;
	padding-top: 0;
	padding-bottom: 0;
	transform: perspective(725px);
}

.link-text-2.is-09 {
	font-family: Heebo, sans-serif;
	font-size: 16px;
}

.link-09_underline {
	width: 100%;
	height: 1px;
	background-color: #131313;
	position: absolute;
	top: auto;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.arrow_image {
	width: auto;
	height: auto;
	object-fit: contain;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid rgba(0, 0, 0, 0);
}

.link-09 {
	width: 78px;
	grid-column-gap: 1rem;
	color: #131313;
	justify-content: flex-start;
	align-self: center;
	align-items: center;
	text-decoration: none;
	display: flex;
	position: relative;
	overflow: hidden;
}

.arrow-wrapper {
	width: auto;
	height: 100%;
	max-height: 100%;
	flex: none;
	justify-content: center;
	align-self: center;
	align-items: center;
	display: flex;
	position: relative;
	top: 2px;
}

.arrow-wrapper.is-after {
	margin-right: 0;
	padding-right: 0;
}

.arrow-wrapper.is-before {
	height: auto;
	max-height: 100%;
	flex-flow: row;
	flex: none;
	align-self: center;
	align-items: center;
	display: flex;
	position: absolute;
	top: auto;
	bottom: 20%;
	left: -1.5rem;
	right: auto;
}

.button {
	width: auto;
	height: 40px;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding: 10px 18px;
	font-weight: 700;
	text-decoration: none;
	display: flex;
	position: relative;
}

.button:hover {
	text-decoration: none;
}

.button-text {
	color: #fff;
	text-transform: uppercase;
	align-items: center;
	margin-top: 2px;
	padding-right: 0;
	font-family: Heebo, sans-serif;
	font-weight: 400;
	line-height: 15px;
	display: flex;
}

.arrow-wrap {
	width: 30px;
	height: 30px;
	justify-content: center;
	align-items: center;
	margin-left: 5px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.arrow-circle {
	width: 20px;
	height: 20px;
	border: 1px solid #047dbf;
	border-radius: 30px;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	position: relative;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
	overflow: hidden;
}

.arrow-container {
	width: 14px;
	height: 14px;
	justify-content: center;
	align-items: center;
	display: flex;
	position: absolute;
	overflow: hidden;
}

.button-arrow-2 {
	width: 10px;
	height: 10px;
	position: absolute;
}

.button-arrow {
	width: 10px;
	height: 10px;
	margin-left: -100%;
	position: absolute;
}

.button-with-arrow {
	width: 80%;
	height: 50px;
	opacity: 0.8;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 4px;
	justify-content: flex-start;
	align-items: center;
	padding-left: 20px;
	padding-right: 0;
	display: flex;
	position: relative;
	overflow: hidden;
}

.button-with-arrow:active {
	opacity: 1;
}

.text-cta {
	z-index: 1;
	color: #047dbf;
	text-transform: uppercase;
	font-family: Heebo, sans-serif;
	font-size: 14px;
	font-weight: 400;
	position: relative;
	left: 12px;
}

.blob {
	width: 20px;
	height: 20px;
	background-color: #faa831;
	border-radius: 99999px;
	position: absolute;
	top: auto;
	bottom: auto;
	left: 2px;
	right: auto;
}

.block-arrow {
	width: 50px;
	height: 50px;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 4px;
	justify-content: flex-start;
	align-items: center;
	margin-left: 10px;
	display: flex;
	position: relative;
	left: 8px;
	overflow: hidden;
}

.null-arrow {
	justify-content: center;
	align-items: center;
	display: flex;
	position: relative;
}

.arrow-stem {
	width: 68px;
	height: 2px;
	background-color: #fff;
}

.arrow-tip {
	width: 10px;
	height: 10px;
	border-bottom: 2px solid #000;
	border-right: 2px solid #000;
	margin-left: -10.5px;
	transform: rotate(-45deg);
}

.div-block-125 {
	width: 100%;
	background-color: #047dbf;
	margin-top: 20px;
}

.content-wrap-stats-copy {
	width: 100%;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	text-align: left;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 20px;
	flex-direction: column;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	align-items: flex-start;
	justify-items: start;
	padding: 0;
	display: flex;
}

.slider-learn-more-copy {
	width: auto;
	height: 45px;
	align-items: center;
	margin-bottom: 20px;
	display: flex;
	overflow: hidden;
}

.div-block-23-copy {
	background-color: #047dbf;
}

.slider-graphic-copy {
	height: 100%;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	background-image: none;
	background-position: 0 0;
	background-size: auto;
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	justify-content: flex-end;
	align-items: center;
	justify-items: center;
	padding-bottom: 100px;
	padding-left: 60px;
	padding-right: 0;
	display: grid;
	overflow: hidden;
}

.heading-4-copy {
	color: #fff;
	text-transform: none;
	text-shadow: -1px 1px 8px rgba(0, 0, 0, 0.65);
	margin-top: 140px;
	font-size: 50px;
	font-weight: 200;
	line-height: 60px;
}

.account-login-wrapper-copy {
	z-index: 10;
	width: 100%;
	height: 100%;
	max-height: 330px;
	max-width: 370px;
	opacity: 1;
	background-color: #fff;
	background-image: none;
	background-position: 0 0;
	border-radius: 0.75rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	padding: 2rem;
	display: flex;
	position: relative;
	top: 0%;
	bottom: 0%;
	left: auto;
	right: 0%;
}

.online-banking-heading-copy {
	width: 85%;
	color: #160042;
	text-align: left;
	text-transform: none;
	text-shadow: none;
	align-items: flex-end;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	font-size: 16px;
	font-weight: 800;
	line-height: 36px;
	display: block;
}

.section-2 {
	margin-top: 7%;
	margin-left: 2%;
	margin-right: 2%;
	display: flex;
}

.slide-arrow {
	font-size: 24px;
}

.text-block-5-copy {
	max-width: 400px;
	color: #fff;
	text-shadow: none;
	font-size: 16px;
	font-weight: 300;
	line-height: 25px;
}

.div-block-45 {
	width: 50%;
	display: flex;
}

.online-banking-form-copy {
	width: 85%;
	justify-content: center;
	margin-bottom: 0;
	display: flex;
}

.slider-copy {
	width: auto;
	height: 650px;
	background-color: #160042;
	flex-direction: column;
	justify-content: flex-start;
	display: block;
}

.login-button {
	width: 100%;
	height: auto;
	color: #fff;
	background-color: #e21d38;
	border: 2px solid #e21d38;
	border-radius: 0 0 10px 10px;
	justify-content: center;
	align-items: center;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 0;
	padding: 9px 40px;
	font-family: Montserrat, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: flex;
}

.login-button:hover {
	color: #fff;
	background-color: #2d0896;
	border-style: solid;
	border-color: #fff;
}

.div-block-26 {
	width: 100%;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 5px;
	margin-bottom: 0;
	display: flex;
}

.right-arrow-copy {
	height: 50px;
	background-color: rgba(255, 255, 255, 0.3);
	margin-bottom: 40px;
	top: auto;
	bottom: 0%;
	left: auto;
	right: 40px;
}

.slide-nav-copy {
	height: 50px;
	padding-top: 20px;
	padding-bottom: 20px;
	display: none;
}

.slide-copy {
	background-image: linear-gradient(rgba(0, 0, 0, 0.29), rgba(0, 0, 0, 0.29)), none, none, none;
	background-position: 0 0, 0 0, 50%, 50%;
	background-repeat: repeat, repeat, repeat, no-repeat;
	background-size: auto, auto, cover, cover;
}

.online-banking {
	width: 50%;
	height: 650px;
	background-color: #e21d38;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
	display: flex;
}

.slider-2-copy-copy {
	height: 650px;
}

.slide-link {
	color: #fff;
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	font-family: Montserrat, sans-serif;
	font-weight: 600;
	transition: all 0.45s;
}

.slide-link:hover {
	padding-right: 0;
}

.slide-link.enroll {
	text-align: left;
	border-bottom: 2px solid #fff;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
}

.slide-link.enroll:hover {
	opacity: 0.65;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.slide-link.enroll.padding-10 {
	color: #160042;
	justify-content: flex-end;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 12px;
	font-weight: 500;
	display: flex;
}

.slide-link.enroll.hidden {
	display: none;
}

.div-block-46 {
	width: 50%;
	justify-content: flex-end;
	display: flex;
}

.form-2-copy {
	font-family: Montserrat, sans-serif;
}

.text-field-2 {
	border: 1px solid #d6d0d0;
	border-radius: 0;
}

.left-arrow-copy {
	height: 50px;
	background-color: rgba(255, 255, 255, 0.3);
	flex-direction: row;
	justify-content: flex-end;
	margin-bottom: 40px;
	display: block;
	top: auto;
	bottom: 0%;
	left: auto;
	right: 120px;
}

.slide-butotn {
	color: #fff;
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	font-family: Montserrat, sans-serif;
	font-weight: 600;
	transition: all 0.45s;
}

.slide-butotn:hover {
	padding-right: 0;
}

.slide-butotn-copy {
	color: #fff;
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	font-family: Montserrat, sans-serif;
	font-weight: 600;
	transition: all 0.45s;
}

.slide-butotn-copy:hover {
	padding-right: 0;
}

.div-block-44 {
	width: 100%;
	justify-content: flex-start;
	display: flex;
}

.select-field {
	width: 100%;
	float: left;
	border-radius: 10px 10px 0 0;
	margin-bottom: 10px;
	font-family: Lato, sans-serif;
	display: flex;
}

.slide-2-copy {
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), none, none;
	background-position: 0 0, 0 0, 50%;
	background-size: auto, auto, cover;
}

.gdrp-link-copy {
	color: #fff;
	text-align: center;
	background-color: rgba(0, 0, 0, 0);
	margin-right: 20px;
	padding: 10px;
	font-family: Poppins, sans-serif;
	font-weight: 700;
	display: flex;
}

.gdrp-link-copy:hover {
	color: #8ab0dd;
}

.home-content-footer {
	z-index: 5000;
	background-image: linear-gradient(rgba(255, 255, 255, 0.72), rgba(255, 255, 255, 0.72)), url('/20181016082423/assets/images/pattern-2-04-min.png');
	background-position: 0 0, 50%;
	background-repeat: repeat, repeat-x;
	background-size: auto, 1500px;
	background-attachment: scroll, scroll;
	border: 1px #000;
	display: none;
	position: static;
}

.button-link-2 {
	color: #fff;
	text-transform: uppercase;
	white-space: normal;
	cursor: pointer;
	border-bottom: 4px #000;
	margin-top: 40px;
	padding-top: 0;
	font-family: Open Sans, sans-serif;
	font-size: 13px;
	font-weight: 700;
	text-decoration: none;
}

.button-link-2.cookies {
	margin-top: 20px;
}

.exit-icon-div {
	width: 25px;
	height: 25px;
	opacity: 0.65;
	background-image: url('/20181016082423/assets/images/close-icon-lt-grey.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	border-bottom-style: none;
	text-decoration: none;
	transition-property: none;
	position: absolute;
	top: 1rem;
	bottom: auto;
	left: auto;
	right: 1rem;
}

.exit-icon-div:hover {
	opacity: 1;
}

.cookie-div {
	width: 33.33%;
	min-width: 425px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.container-6 {
	height: 100%;
	max-width: 1280px;
	min-height: 100%;
	padding-left: 20px;
	padding-right: 20px;
}

.container-6.footer {
	justify-content: space-between;
	align-items: flex-start;
	padding-top: 3rem;
	padding-bottom: 3rem;
	display: flex;
}

.container-6.footer.gdrp {
	max-width: none;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: row;
	padding-top: 2rem;
	padding-bottom: 2rem;
	position: relative;
}

.cookie-text-para {
	width: 66.66%;
	color: #fff;
	padding-left: 10px;
	padding-right: 10px;
	font-family: Poppins, sans-serif;
	font-size: 0.8rem;
	line-height: 1.3rem;
}

.open-gdrp-icon {
	width: 50px;
	height: 50px;
	background-image: url('/20181016082423/assets/images/dark-blue-gear-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: auto;
	border-bottom-style: none;
	margin-right: 4rem;
	padding-right: 0;
	text-decoration: none;
	transition-property: none;
}

.open-gdrp-icon:hover {
	background-image: url('/20181016082423/assets/images/light-blue-gear-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: auto;
}

.gdrp-section-icon {
	z-index: 1000000000;
	min-height: 100px;
	background-color: #fff;
	border-top: 4px #d3d3d3;
	justify-content: center;
	display: block;
	position: fixed;
	top: auto;
	bottom: 0%;
	left: 0%;
	right: 0%;
	box-shadow: 0 -13px rgba(179, 182, 183, 0.75);
}

.gdrp-section-icon.just-icon {
	z-index: 100000000;
	width: 100px;
	min-height: 120px;
	box-shadow: none;
	background-color: rgba(0, 0, 0, 0);
	justify-content: center;
	display: flex;
	top: auto;
	bottom: 0%;
	left: auto;
	right: 0%;
}

.gdrp-link {
	color: #fff;
	text-align: center;
	background-color: #08255f;
	margin-right: 20px;
	padding: 10px;
	font-family: Poppins, sans-serif;
	font-weight: 700;
	display: flex;
}

.gdrp-link:hover {
	color: #8ab0dd;
}

.image-29 {
	margin-top: -15px;
	margin-left: 10px;
	padding-top: 0;
}

.button-underline {
	height: auto;
	cursor: pointer;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: -20px;
	transition: transform 0.4s;
	display: flex;
	transform: translate(0);
}

.button-underline:hover {
	transform: scale(1.1);
}

.button-underline.gdpr {
	display: none;
}

.gdrp-section {
	z-index: 1000000000;
	min-height: 100px;
	background-color: #333;
	border-top: 4px #047dbf;
	justify-content: center;
	display: none;
	position: fixed;
	top: auto;
	bottom: 0%;
	left: 0%;
	right: 0%;
	box-shadow: 0 -5px #8ab0dd;
}

.footer-signoff-links-copy {
	border-bottom: 1px solid #1d1d1b;
	margin-right: 1rem;
	line-height: 1.25rem;
	display: none;
}

.footer-signoff-links-copy:hover {
	color: #fff;
	border-bottom-color: #faa831;
}

.footer-signoff-link-2-copy {
	color: #1d1d1b;
	letter-spacing: 1px;
	font-family: Heebo, sans-serif;
	font-size: 0.8rem;
	font-weight: 300;
	text-decoration: none;
	transition: color 0.3s;
	display: none;
}

.footer-signoff-link-2-copy:hover {
	color: #faa831;
}

.promo-counter-copy {
	opacity: 1;
	background-color: rgba(221, 158, 4, 0.2);
	display: none;
}

.icon-small {
	height: 2rem;
}

.button-3 {
	color: #faa831;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	font-family: Heebo, sans-serif;
	font-size: 0.9rem;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.45s;
}

.button-3:hover {
	color: #faa831;
	-webkit-text-stroke-color: #160042;
	padding-right: 0;
	text-decoration: underline;
}

.padding-section-medium {
	padding-top: 6rem;
	padding-bottom: 6rem;
}

.heading-2 {
	color: #047dbf;
	-webkit-text-stroke-color: #67011f;
	text-transform: uppercase;
	margin-top: 10px;
	font-family: minerva-modern, sans-serif;
	font-size: 40px;
	font-weight: 400;
	line-height: 35px;
}

.heading-2.right-column {
	font-size: 30px;
	line-height: 29px;
}

.features_grid {
	grid-column-gap: 2.5rem;
	grid-row-gap: 2rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.div-block-2 {
	width: 100%;
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	align-items: flex-start;
	display: flex;
}

.arrow-2 {
	width: auto;
	height: auto;
	position: static;
	overflow: hidden;
}

.arrow-2:hover {
	width: auto;
	height: auto;
}

.news-section {
	color: #374957;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 140px;
	display: flex;
	position: relative;
	z-index: 1000;
}

.features_item {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	background-color: #fff;
	border-radius: 1.25rem;
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	align-content: start;
	align-items: start;
	padding: 2rem;
	display: grid;
}

.div-block-12 {
	width: 100%;
	max-width: 1200px;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	justify-content: space-between;
	display: none;
}

.div-block-7 {
	width: auto;
	height: auto;
	position: static;
	overflow: hidden;
}

.div-block-7:hover {
	width: auto;
	height: auto;
}

.container-large {
	width: 100%;
	max-width: 80rem;
	margin-left: auto;
	margin-right: auto;
}

.div-block-5 {
	height: 60%;
	border-radius: 5px;
	justify-content: flex-end;
	display: flex;
	overflow: hidden;
}

.features_components {
	grid-column-gap: 4rem;
	grid-row-gap: 4rem;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.div-block-6 {
	width: auto;
	height: 45px;
	align-items: center;
	margin-bottom: 20px;
	display: flex;
	overflow: hidden;
}

.image-3 {
	width: 120%;
	max-width: none;
	border-radius: 5px;
}

.image-3.img-zoom {
	transition: all 0.45s;
}

.image-3.img-zoom:hover {
	transform: scale(1.1);
}

.text-block-3 {
	color: #374957;
	display: none;
}

.features_header {
	grid-column-gap: 3rem;
	grid-row-gap: 4rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.section_features {
	background-color: #edf7ff;
	display: none;
}

.padding-global {
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}

.grey-arrow-3 {
	width: 0;
	margin-left: 10px;
}

.footer-2 {
	width: 100%;
	height: auto;
	background-color: #047dbf;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	padding-top: 20px;
	padding-left: 0%;
	padding-right: 0%;
	display: flex;
}

.text-span-white {
	color: #fff;
	margin-bottom: 30px;
	font-family: Heebo, sans-serif;
	font-size: 16px;
	font-weight: 500;
	display: inline-block;
}

.text-span-5-b {
	color: #fff;
	font-size: 17px;
}

.text-span-copy {
	color: #fff;
	padding-top: 10px;
	padding-bottom: 20px;
	font-family: Heebo, sans-serif;
	font-size: 16px;
	font-weight: 300;
	display: inline-block;
}

.text-span-copy:hover {
	text-decoration: underline;
}

.text-span-copy {
	padding-top: 10px;
	padding-bottom: 20px;
	font-family: Heebo, sans-serif;
	font-size: 16px;
	font-weight: 300;
	display: inline-block;
}

.text-span-copy:hover {
	text-decoration: underline;
}

.text-span-copy {
	padding-top: 10px;
	padding-bottom: 20px;
	font-family: Heebo, sans-serif;
	font-size: 16px;
	font-weight: 300;
	display: inline-block;
}

.text-span-copy:hover {
	text-decoration: underline;
}

.text-span-copy {
	padding-top: 10px;
	padding-bottom: 20px;
	font-family: Heebo, sans-serif;
	font-size: 16px;
	font-weight: 300;
	display: inline-block;
}

.text-span-copy:hover {
	text-decoration: underline;
}

.text-span-3-copy {
	color: #fff;
	color: #fff;
	color: #fff;
	color: #fff;
	font-family: Heebo, sans-serif;
	font-size: 16px;
	font-weight: 300;
}

.footer_title-2 {
	color: #374957;
	font-size: 1.125rem;
	font-weight: 600;
}

.padding-bottom {
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
}

.footer_link-2 {
	color: #374957;
	margin-top: 0.5rem;
	font-weight: 300;
	transition: opacity 0.2s;
}

.footer_link-2:hover {
	opacity: 1;
	color: #67011f;
}

.footer-social-icon-link-2 {
	width: 2.5rem;
	height: 2.5rem;
	min-width: 0;
	float: none;
	color: #2a779b;
	background-color: #faa831;
	border-radius: 20px;
	margin: 0.5rem 0.5rem 0.25rem 0;
	padding: 4px;
	transition: all 0.2s;
}

.footer-social-icon-link-2:hover {
	background-color: #047dbf;
}

.footer_links-col-7 {
	color: #374957;
	flex-direction: column;
	align-items: flex-start;
	display: none;
}

.footer-social-icon-image {
	float: left;
	color: #025e8a;
}

.footer-social-icon-image.youtube {
	padding: 1px;
}

.div-block-130 {
	width: auto;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	display: flex;
}

.link-list-title-2 {
	width: 100%;
	color: #fff;
	text-align: center;
	text-transform: capitalize;
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-family: Heebo, sans-serif;
	font-size: 16px;
	font-weight: 600;
	display: flex;
}

.brand-logo-footer {
	width: 75%;
	height: auto;
	flex: none;
}

.text-span-white-2 {
	color: #fff;
	margin-bottom: 30px;
	font-family: Heebo, sans-serif;
	font-size: 16px;
	font-weight: 500;
	display: inline-block;
}

.text-block-8-copy {
	color: #fff;
	text-transform: capitalize;
	border-left: 1px solid #047dbf;
	margin-left: 0;
	padding-left: 0;
	font-family: minerva-modern, sans-serif;
	font-size: 32px;
	font-weight: 700;
	line-height: 42px;
}

.text-span-5-c {
	color: #faa831;
	font-size: 17px;
}

.contact-div {
	width: auto;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	display: flex;
}

.text-block-8-copy {
	color: #1d1d1b;
	text-transform: capitalize;
	border-left: 1px solid #047dbf;
	margin-left: 0;
	padding-left: 0;
	font-family: minerva-modern, sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 22px;
}

.text-block-34 {
	color: #fff;
	margin-top: 20px;
	padding-bottom: 20px;
	font-family: minerva-modern, sans-serif;
	font-size: 32px;
	line-height: 40px;
}

.text-block-35 {
	color: #faa831;
	margin-top: 15px;
	margin-bottom: 10px;
	font-family: minerva-modern, sans-serif;
	font-size: 20px;
}

.link-10 {
	color: #fff;
	padding-top: 2px;
	padding-bottom: 2px;
	font-family: Heebo, sans-serif;
	text-decoration: none;
	display: inline-block;
}

.link-10:hover {
	text-decoration: underline;
}

.div-block-17-copy-copy,
.quick-links-div {
	width: auto;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	display: flex;
}

.text-block-36 {
	width: auto;
	color: #fff;
	font-family: Heebo, sans-serif;
}

.div-block-17-copy {
	width: 25%;
	width: 25%;
	width: 25%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 60px;
	display: flex;
}

.text-block-35-copy {
	color: #faa831;
	margin-top: 10px;
	margin-bottom: 10px;
	font-family: minerva-modern, sans-serif;
	font-size: 20px;
	display: none;
}

.brand-logo-footer-copy {
	width: 60%;
	height: auto;
	flex: none;
	display: none;
}

.link-10-copy {
	color: #fff;
	padding-top: 0;
	padding-bottom: 0;
	font-family: Heebo, sans-serif;
	text-decoration: none;
}

.link-10-copy:hover {
	text-decoration: underline;
}

.uui-space-xsmall-4 {
	width: 100%;
	min-height: 1rem;
}

.uui-form_input-2 {
	height: auto;
	min-height: 2.75rem;
	color: #101828;
	background-color: #fff;
	border: 1px solid #d0d5dd;
	border-radius: 0.5rem;
	margin-bottom: 0;
	padding: 0.5rem 0.875rem;
	font-family: Heebo, sans-serif;
	font-size: 1rem;
	line-height: 1.5;
	transition: all 0.3s;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-form_input-2:focus {
	color: #101828;
	border-color: rgba(0, 112, 198, 0.1);
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(0, 112, 198, 0.1);
}

.uui-form_input-2::-ms-input-placeholder {
	color: #667085;
}

.uui-form_input-2::placeholder {
	color: #667085;
}

.uui-form_input-2.select {
	color: #101828;
	background-color: #fff;
}

.uui-form_input-2.text-area {
	height: auto;
	min-height: 11.25rem;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	overflow: auto;
}

.uui-form-radio-icon {
	width: 1.25rem;
	height: 1.25rem;
	min-height: 1.25rem;
	min-width: 1.25rem;
	cursor: pointer;
	background-color: #fff;
	border: 1px solid #d0d5dd;
	border-radius: 100px;
	margin-top: 0;
	margin-left: -1.125rem;
	margin-right: 0.75rem;
	transition: all 0.3s;
}

.uui-form-radio-icon:hover {
	background-color: #f9f5ff;
	border-color: #0871b9;
}

.uui-form-radio-icon.w--redirected-checked {
	background-color: #f9f5ff;
	background-image: url('/20181016082423/assets/images/check-circle.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: auto;
	border-width: 1px;
	border-color: #0871b9;
}

.uui-form-radio-icon.w--redirected-focus {
	background-color: #f9f5ff;
	border-color: #0871b9;
	margin-top: 0;
	box-shadow: 0 0 0 4px #f4ebff;
}

.uui-form-radio-icon.tick-icon.w--redirected-checked {
	background-color: #0871b9;
	background-image: url('/20181016082423/assets/images/check-tick.svg');
}

.uui-form-button-wrapper {
	flex-direction: column;
	margin-top: 0.5rem;
	display: flex;
}

.uui-contact02_component {
	max-width: 35rem;
	flex-direction: column;
	align-items: stretch;
	margin: 4rem auto 0;
}

.uui-form-field-wrapper {
	position: relative;
}

.error-message-2 {
	margin-top: 1.5rem;
	padding: 0.875rem 1rem;
}

.form-radio-2col {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	margin-top: 1rem;
	display: grid;
}

.uui-button-7 {
	grid-column-gap: 0.5rem;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #047dbf;
	border: 1px solid #047dbf;
	border-radius: 0.5rem;
	justify-content: center;
	align-items: center;
	padding: 0.625rem 1.125rem;
	font-family: Heebo, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.uui-button-7:hover {
	background-color: #faa831;
	border-color: #faa831;
}

.uui-button-7:focus {
	background-color: #0871b9;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(0, 112, 198, 0.1);
}

.uui-form-radio {
	align-items: center;
	margin-bottom: 0;
	padding-left: 1.125rem;
	display: flex;
}

.contact-form-2 {
	font-family: Heebo, sans-serif;
	display: none;
}

.uui-container-small {
	width: 100%;
	max-width: 48rem;
	margin-left: auto;
	margin-right: auto;
}

.uui-text-align-center-5 {
	text-align: center;
}

.error-text {
	color: #e23939;
}

.uui-padding-vertical-xhuge-7 {
	padding-top: 7rem;
	padding-bottom: 7rem;
}

.uui-contact02_form {
	grid-column-gap: 1.5rem;
	grid-row-gap: 1.5rem;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.uui-heading-subheading-4 {
	color: #0871b9;
	margin-bottom: 0.75rem;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
}

.success-text {
	font-weight: 600;
}

.uui-max-width-large-5 {
	width: 100%;
	max-width: 48rem;
}

.uui-max-width-large-5.align-center {
	margin-left: auto;
	margin-right: auto;
}

.uui-field-label {
	color: #344054;
	margin-bottom: 0.5rem;
	font-family: Heebo, sans-serif;
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1.5;
}

.uui-form-checkbox {
	align-items: center;
	margin-bottom: 0;
	padding-left: 1.25rem;
	display: none;
}

.success-message-2 {
	background-color: #f4f4f4;
	padding: 1.5rem;
}

.uui-form-radio-label {
	color: #344054;
	margin-bottom: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-weight: 500;
}

.uui-text-size-large-5 {
	color: #475467;
	letter-spacing: normal;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1.125rem;
	line-height: 1.5;
	display: none;
}

.uui-form-checkbox-label {
	color: #344054;
	margin-bottom: 0;
	font-family: Heebo, sans-serif;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5;
}

.uui-text-style-link-2 {
	color: #475467;
	text-decoration: underline;
	transition: color 0.3s;
}

.uui-text-style-link-2:hover {
	color: #344054;
}

.uui-form-checkbox-icon {
	width: 1.25rem;
	height: 1.25rem;
	min-height: 1.25rem;
	min-width: 1.25rem;
	cursor: pointer;
	background-color: #fff;
	border: 1px solid #d0d5dd;
	border-radius: 0.375rem;
	margin-top: 0;
	margin-left: -1.25rem;
	margin-right: 0.75rem;
	transition: all 0.3s;
}

.uui-form-checkbox-icon:hover {
	background-color: #f9f5ff;
	border-color: #0871b9;
}

.uui-form-checkbox-icon.w--redirected-checked {
	box-shadow: none;
	background-color: #f9f5ff;
	background-image: url('/20181016082423/assets/images/check.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 0.875rem 0.875rem;
	background-attachment: scroll;
	border-width: 1px;
	border-color: #0871b9;
	border-radius: 0.25rem;
}

.uui-form-checkbox-icon.w--redirected-focus {
	background-color: #f9f5ff;
	border-color: #0871b9;
	border-radius: 0.25rem;
	box-shadow: 0 0 0 4px #f4ebff;
}

.form-field-2col {
	grid-column-gap: 1.5rem;
	grid-row-gap: 1.5rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.uui-form-field-wrapper-copy {
	display: none;
	position: relative;
}

.team_item_promo {
	width: 100%;
	border-radius: 0;
	position: relative;
	overflow: hidden;
}

.two-box-promo {
	width: 100%;
	max-width: 1200px;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.team_image {
	width: 100%;
	height: 250px;
	object-fit: cover;
}

.block-arrow-2 {
	width: 80px;
	height: 50px;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 4px;
	justify-content: flex-start;
	align-items: center;
	margin-left: 10px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.text-cta-3 {
	z-index: 1;
	color: #fff;
	text-transform: uppercase;
	font-family: Heebo, sans-serif;
	font-size: 13px;
	font-weight: 400;
	position: relative;
}

.blob-2 {
	width: 40px;
	height: 40px;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 99999px;
	display: block;
	position: absolute;
	top: auto;
	bottom: auto;
	left: 6px;
	right: auto;
}

.team_description-copy {
	color: #fff;
	margin-bottom: 0;
	font-size: 0.875rem;
	font-weight: 400;
	display: none;
}

.button-with-arrow-copy {
	width: auto;
	height: 50px;
	opacity: 0.8;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 4px;
	justify-content: flex-start;
	align-items: center;
	margin-top: 0;
	padding-left: 0;
	padding-right: 19px;
	display: none;
	position: relative;
	overflow: hidden;
}

.button-with-arrow-copy:active {
	opacity: 1;
}

.heading-13 {
	text-transform: none;
	font-family: minerva-modern, sans-serif;
	font-size: 22px;
	font-weight: 400;
	color: #fff;
	line-height: 35px;
	margin-top: 20px;
}

.team_content-2 {
	color: #fff;
	background-color: rgba(0, 112, 198, 0.8);
	border-radius: 0;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0.5rem 2rem 1rem;
	position: absolute;
	top: auto;
	bottom: 15%;
	left: 0%;
	right: 15%;
}

.arrow-stem-3 {
	width: 68px;
	height: 2px;
	background-color: #fff;
}

.text-size-small-copy {
	font-size: 0.875rem;
}

.text-size-small-copy.text-style-muted {
	display: none;
}

.team_name-copy-2 {
	color: #fff;
	margin-top: 10px;
	font-family: PT Serif, serif;
	font-size: 60px;
	font-weight: 500;
	line-height: 1.5;
	display: none;
}

.nav-link-out {
	color: #fff;
	text-transform: uppercase;
	align-items: center;
	padding: 1.75rem 1rem;
	font-family: minerva-modern, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
	display: none;
}

.nav-link-out:hover {
	color: #faa831;
}

.nav-link-out.w--current {
	color: #000;
}

.button-with-arrow-copy-copy {
	width: auto;
	height: 50px;
	opacity: 0.8;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 4px;
	justify-content: flex-start;
	align-items: center;
	margin-top: 0;
	padding-left: 0;
	padding-right: 19px;
	display: none;
	position: relative;
	overflow: hidden;
}

.button-with-arrow-copy-copy:active {
	opacity: 1;
}

.button-with-arrow-services {
	width: auto;
	height: 50px;
	opacity: 0.8;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 4px;
	justify-content: center;
	align-items: center;
	padding-left: 19px;
	padding-right: 19px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.button-with-arrow-services:active {
	opacity: 1;
}

.text-cta-services {
	z-index: 1;
	color: #fff;
	text-transform: uppercase;
	font-family: Heebo, sans-serif;
	font-size: 14px;
	font-weight: 400;
	position: relative;
	left: 12px;
}

.block-arrow-services {
	width: 60px;
	height: 50px;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 4px;
	justify-content: flex-start;
	align-items: center;
	margin-left: 10px;
	display: flex;
	position: relative;
	left: 8px;
	overflow: hidden;
}

.blob-services {
	width: 20px;
	height: 20px;
	background-color: #faa831;
	border-radius: 99999px;
	position: absolute;
	top: auto;
	bottom: auto;
	left: 2px;
	right: auto;
}

.null-arrow-services {
	justify-content: center;
	align-items: center;
	display: flex;
	position: relative;
}

.arrow-stem-copy {
	width: 68px;
	height: 2px;
	width: 68px;
	height: 2px;
	width: 68px;
	height: 2px;
	background-color: #047dbf;
}

.slide-3-b {
	background-position: 0 0, 50%;
	background-size: auto, cover;
}

.lynx-block-hero-2-copy {
	z-index: 10;
	width: 40%;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-top: 140px;
	margin-left: 12%;
	display: flex;
	position: absolute;
	top: 80px;
	left: 0;
}

.icon-link-wrapper-copy {
	width: 250px;
	height: 200px;
	opacity: 1;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.8);
	border: 1px solid rgba(0, 0, 0, 0);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 0;
	margin-right: 0;
	padding-top: 1rem;
	padding-bottom: 0;
	padding-right: 0;
	line-height: 25px;
	text-decoration: none;
	display: flex;
}

.footer-signoff-links-copy {
	border-bottom: 0 solid #1d1d1b;
	margin-right: 1rem;
	line-height: 1.25rem;
	display: inline-block;
}

.footer-signoff-links-copy:hover {
	color: #fff;
	border-bottom-color: #faa831;
}

.uui-navbar02_item-heading-2-copy {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: uppercase;
	margin-bottom: 0.25rem;
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
}

.uui-navbar02_item-heading-2-copy:hover {
	color: #faa831;
}

.uui-navbar02_item-heading-2-copy {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: uppercase;
	margin-bottom: 0.25rem;
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
}

.uui-navbar02_item-heading-2-copy:hover {
	color: #faa831;
}

.uui-navbar02_item-heading-2-copy {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: uppercase;
	margin-bottom: 0.25rem;
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
}

.uui-navbar02_item-heading-2-copy:hover {
	color: #faa831;
}

.uui-navbar02_item-heading-2-copy {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: uppercase;
	margin-bottom: 0.25rem;
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
}

.uui-navbar02_item-heading-2-copy:hover {
	color: #faa831;
}

.uui-navbar02_item-heading-2-copy {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: uppercase;
	margin-bottom: 0.25rem;
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
}

.uui-navbar02_item-heading-2-copy:hover {
	color: #faa831;
}

.uui-navbar02_item-heading-2-copy {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: uppercase;
	margin-bottom: 0.25rem;
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
}

.uui-navbar02_item-heading-2-copy:hover {
	color: #faa831;
}

.uui-navbar02_item-heading-2-copy {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: uppercase;
	margin-bottom: 0.25rem;
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
}

.uui-navbar02_item-heading-2-copy:hover {
	color: #faa831;
}

.text-span-2-copy {
	color: #047dbf;
	color: #047dbf;
	color: #047dbf;
	margin-top: 22px;
	padding-bottom: 10px;
	font-size: 17px;
	display: inline-block;
}

.text-block-36-copy {
	width: auto;
	color: #fff;
	margin-bottom: 10px;
	font-family: Heebo, sans-serif;
}

.text-block-36-copy a,
.text-block-36-copy a:hover {
	color: #fff;
	font-family: Heebo, sans-serif;
	text-decoration: none;
}

.gdrp-section-2 {
	z-index: 1000000000;
	min-height: 100px;
	background-color: #333;
	border-top: 4px #d3d3d3;
	justify-content: center;
	display: none;
	position: fixed;
	top: auto;
	bottom: 0%;
	left: 0%;
	right: 0%;
	box-shadow: 0 -5px #1d1d1b;
}

.image-32 {
	margin-top: -15px;
	margin-left: 10px;
	padding-top: 0;
}

.gdrp-link-copy-2 {
	color: #fff;
	text-align: center;
	background-color: rgba(0, 0, 0, 0);
	margin-right: 20px;
	padding: 10px;
	font-family: Heebo, sans-serif;
	font-size: 14px;
	font-weight: 400;
	text-decoration: none;
	display: flex;
}

.gdrp-link-copy-2:hover {
	color: #fff;
	text-decoration: underline;
}

.open-gdrp-icon-2 {
	width: 50px;
	height: 50px;
	background-color: #fff;
	background-image: url('/20181016082423/assets/images/dark-blue-gear-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: auto;
	border: 1px solid #e6e6e6;
	border-radius: 50px;
	margin-right: 0;
	padding-right: 0;
	text-decoration: none;
	transition-property: none;
}

.open-gdrp-icon-2:hover {
	background-image: url('/20181016082423/assets/images/light-blue-gear-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: auto;
	border: 1px solid #e6e6e6;
}

.home-content-footer-2 {
	z-index: 5000;
	background-image: linear-gradient(rgba(255, 255, 255, 0.72), rgba(255, 255, 255, 0.72)), url('/20181016082423/assets/images/pattern-2-04-min.png');
	background-position: 0 0, 50%;
	background-repeat: repeat, repeat-x;
	background-size: auto, 1500px;
	background-attachment: scroll, scroll;
	border: 1px #000;
	position: static;
}

.cookie-text-para-2 {
	width: 66.66%;
	color: #fff;
	padding-left: 10px;
	padding-right: 10px;
	font-family: Heebo, sans-serif;
	font-size: 14px;
	line-height: 1.3rem;
}

.gdrp-link-2 {
	color: #fff;
	text-align: center;
	background-color: #047dbf;
	margin-right: 20px;
	padding: 10px;
	font-family: Heebo, sans-serif;
	font-size: 14px;
	font-weight: 400;
	text-decoration: none;
	display: flex;
}

.gdrp-link-2:hover {
	color: #fff;
	text-decoration: underline;
}

.enquire-now-footer {
	z-index: 1000;
	width: 100px;
	justify-content: center;
	display: flex;
	position: fixed;
	top: auto;
	bottom: 120px;
	left: auto;
	right: 0;
}

.link-block-5 {
	width: 80px;
	height: 156px;
	background-image: url('/20181016082423/assets/images/Enquire-Now-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	margin-right: 0;
}

.link-block-5:hover {
	background-image: url('/20181016082423/assets/images/Enquire-Now-icon-hover.svg');
}

.ui-nav-bar-container {
	background-color: #047dbf;
}

.container {
	width: 100%;
	max-width: 1500px;
	margin-left: auto;
	margin-right: auto;
	padding: 5vw 4vw 3vw;
	font-family: Heebo, sans-serif;
}

.inside-page-header-content-wrap-2 {
	width: 100%;
	border-bottom: 1px #d5e1e6;
	margin-top: 60px;
	margin-bottom: 1em;
	padding-bottom: 0;
	position: relative;
}

.breadcrumb-list {
	color: #313131;
	letter-spacing: 0.02rem;
	text-transform: uppercase;
	margin: 0 0.125rem 1rem;
	padding-top: 0.25em;
	padding-left: 0;
	font-size: 0.8rem;
	line-height: 1rem;
}

.breadcrumb-list-item {
	float: left;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	margin-right: 0.5rem;
}

.text-link-2 {
	color: #313131;
	border-bottom: 1px solid #797676;
	line-height: 1rem;
	transition-duration: 0.2s;
}

.text-link-2:hover {
	color: #721926;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.text-link-2.breadcrumb-link {
	border-bottom-color: rgba(0, 0, 0, 0);
	display: inline-block;
}

.text-link-2.breadcrumb-link:hover {
	color: #2a4187;
	border-bottom-color: #c5d7df;
}

.breadcrumb-divider {
	float: right;
	margin-left: 0.5rem;
}

.heading-16 {
	color: #67011f;
	margin-bottom: 20px;
}

.block-quote {
	background-color: rgba(226, 235, 240, 0.1);
	border-left-color: #faa831;
}

.left-nav-list-2 {
	margin-top: 0;
	margin-bottom: 1.5em;
	padding-left: 0;
	box-shadow: 0 1px #a2b1c4;
}

.left-nav-nested-list-item-2 {
	margin-bottom: 1px;
	box-shadow: 0 1px #a2b1c4;
}

.form-wrap-2 {
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
}

.divider-2 {
	height: 1px;
	clear: both;
	background-color: #c9c6c4;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

.content-wrapper {
	align-items: flex-start;
	display: flex;
}

.heading-21 {
	color: #2a4187;
}

.left-nav-col-2 {
	width: 20%;
	height: 100%;
	min-width: 220px;
	background-color: rgba(0, 0, 0, 0);
	flex: none;
}

.main-content-wrapper {
	padding-top: 0.6rem;
	overflow: hidden;
}

.main-content-wrapper.no-sidebars {
	font-size: 1.125rem;
}

.heading-20 {
	color: #67011f;
}

.inside-page-content-wrap-no-sidebars-2 {
	margin-left: 0%;
	margin-right: 0%;
	width: 100%;
}

.button-4 {
	color: #047dbf;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	font-weight: 600;
	transition: all 0.45s;
}

.button-4:hover {
	color: #2a4187;
	-webkit-text-stroke-color: #160042;
	padding-right: 0;
}

.inside-content-column {
	width: 100%;
	margin-right: 20px;
}

.left-nav-list-item-2 {
	margin-bottom: 1px;
	font-size: 1rem;
	box-shadow: 0 -1px #a2b1c4;
}

.form-error-2 {
	color: #000;
	background-color: #ffccd0;
	border: 2px solid #721926;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
	font-weight: 400;
}

.image-37 {
	margin-bottom: 20px;
}

.button-5 {
	min-width: 100px;
	color: #fff;
	text-align: center;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	background-color: #2a4187;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	margin-right: 1rem;
	padding: 7px 1.15rem 8px;
	font-size: 1rem;
	font-weight: 600;
	text-decoration: none;
	transition: all 0.3s;
	display: inline-block;
}

.button-5:hover {
	color: #fff;
	background-color: #047dbf;
}

.button-5.tertiary-copy {
	min-width: 0;
	color: #313131;
	background-color: #fff;
	border: 1px solid #797676;
	padding: 0.25rem 0.75rem;
	font-size: 0.9rem;
	display: none;
}

.button-5.tertiary-copy:hover {
	background-color: #c9c6c4;
}

.button-5.secondary {
	background-color: #67011f;
}

.button-5.secondary:hover {
	background-color: #047dbf;
}

.button-5.secondary-copy {
	background-color: #797676;
	display: none;
}

.button-5.secondary-copy:hover {
	background-color: #313131;
}

.left-nav-wrapper-2 {
	height: auto;
	border-top: 3px solid #047dbf;
	border-right: 1px #a2b1c4;
	margin-right: 2.25vw;
	padding-top: 0;
	padding-bottom: 4vw;
	display: block;
}

.left-nav-nested-list-link-2 {
	color: #313131;
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	padding: 0.75rem 1rem 0.6875rem 0.5rem;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.2rem;
	text-decoration: none;
	transition: padding 0.2s, background-color 0.2s, color 0.2s;
	display: block;
}

.left-nav-nested-list-link-2:hover {
	color: #2a4187;
	background-image: url('/20181016082423/assets/images/angle-right_6f6e6d.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 6px;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.left-nav-nested-list-link-2.w--current:hover {
	color: #721926;
}

.heading-23 {
	color: #0a0442;
}

.heading-17,
.heading-24 {
	color: #67011f;
}

.header-link {
	color: #721926;
	border-bottom: 1px solid #797676;
}

.header-link:hover {
	color: #797676;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.header-link.h6-link {
	color: #313131;
	border-bottom-width: 0;
}

.header-link.h6-link:hover {
	color: #2a4187;
}

.header-link.h1-link {
	color: #047dbf;
	border-bottom-width: 0;
}

.header-link.h1-link:hover {
	color: #2a4187;
}

.header-link.h3-link {
	color: #047dbf;
	border-bottom-width: 0;
}

.header-link.h3-link:hover {
	color: #2a4187;
}

.header-link.h4-link {
	color: #047dbf;
	border-bottom-width: 0;
}

.header-link.h4-link:hover {
	color: #2a4187;
}

.header-link.h2-link {
	color: #047dbf;
	border-bottom-width: 0;
}

.header-link.h2-link:hover {
	color: #2a4187;
}

.header-link.h5-link {
	color: #047dbf;
	border-bottom-width: 0;
}

.header-link.h5-link:hover {
	color: #2a4187;
}

.button-6 {
	z-index: 5;
	color: #fff;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	cursor: pointer;
	background-color: #2a4187;
	background-image: url('/20181016082423/assets/images/white-arrow-icon-01.svg');
	background-position: 87%;
	background-repeat: no-repeat;
	background-size: 20px;
	border-radius: 0;
	margin-right: 20px;
	padding: 0.75rem 3.75rem 0.75rem 1.25rem;
	font-size: 0.85rem;
	font-weight: 500;
	transition: all 0.4s, background-color 0.4s;
	position: static;
	top: auto;
}

.button-6:hover {
	opacity: 1;
	color: #fff;
	background-color: #047dbf;
}

.button-copy {
	min-width: 100px;
	color: #fff;
	text-align: center;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	background-color: #721926;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	margin-right: 1rem;
	padding: 7px 1.15rem 8px;
	font-size: 1rem;
	font-weight: 600;
	text-decoration: none;
	transition: all 0.3s;
	display: none;
}

.button-copy:hover {
	color: #fff;
	background-color: #313131;
}

.heading-22 {
	color: #2a4187;
}

.text-link-3 {
	color: #313131;
	border-bottom: 0 solid #797676;
	line-height: 1rem;
	transition-property: none;
}

.text-link-3:hover {
	color: #2a4187;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.text-link-3.w--current {
	color: #28313b;
	font-weight: 700;
}

.form-success-2 {
	color: #000;
	background-color: #caf3aa;
	border: 2px solid #48a700;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
}

.left-nav-nested-list-2-copy {
	background-color: rgba(0, 0, 0, 0);
	border-top: 1px solid #a2b1c4;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0 0 0 0.75em;
}

.heading-18 {
	color: #67011f;
}

.left-nav-list-link-2 {
	color: #313131;
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	padding: 0.65rem 1rem 0.65rem 0.5rem;
	line-height: 1.3rem;
	text-decoration: none;
	transition: background-color 0.2s, color 0.2s, padding 0.2s;
	display: block;
}

.left-nav-list-link-2:hover {
	color: #2a4187;
	background-image: none;
	background-size: auto;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.left-nav-list-link-2.w--current:hover {
	color: #721926;
}

.heading-19 {
	color: #67011f;
}

.left-nav-nested-list-2 {
	background-color: rgba(95, 164, 206, 0.3);
	border-top: 1px solid rgba(5, 48, 97, 0.3);
	margin-top: 0;
	margin-bottom: 0;
	padding: 0 0 0 0.75em;
}

.intro-text-style-2 {
	color: #374957;
	margin-bottom: 0.75rem;
	font-family: Heebo, sans-serif;
	font-size: 1rem;
	line-height: 1.6rem;
}

.inside-content-wrapper-2 {
	padding-bottom: 2rem;
	display: flex;
	width: 100%;
	min-height: 500px;
}

.heading-3 {
	color: #047dbf;
	background-color: #fff;
	margin-top: 0.2rem;
	padding-bottom: 0;
	padding-left: 0.5rem;
	padding-right: 0.7rem;
	font-family: minerva-modern, sans-serif;
	font-size: 26px;
}

.heading-4 {
	color: #047dbf;
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
	font-size: 38px;
	line-height: 44px;
	margin-top: 20px;
}

.paragraph-8 {
	font-family: Heebo, sans-serif;
	font-size: 1rem;
	line-height: 24px;
}

.paragraph-9 {
	font-family: Heebo, sans-serif;
}

.right-col {
	width: 20%;
	margin-left: 0%;
	min-width: 124px;
}

.heading-26 {
	color: #67011f;
	margin-top: 10px;
}

.right-col-item-wrap {
	border-top: 3px solid #047dbf;
	margin-bottom: 1.5rem;
	margin-left: 0;
	padding-top: 0.75rem;
	position: relative;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0;
}

.right-col-link {
	color: #313131;
	border-bottom: 1px solid #797676;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.4em;
	transition-duration: 0.2s;
}

.right-col-link:hover {
	color: #2a4187;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.right-col-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.heading-27 {
	color: #67011f;
}

.heading-25 {
	color: #67011f;
	margin-top: 10px;
}

.slide-3-copy {
	background-position: 0 0, 50%;
	background-size: auto, cover;
}

.about-us-copy {
	width: 100%;
	margin-top: 100px;
	overflow: visible;
}

.div-block-17-contact {
	width: 25%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 0;
	display: flex;
}

.div-block-17-copy {
	width: 25%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 20px;
	display: flex;
}

.mobile-quick-links-wrapper {
	z-index: 7;
	width: 80%;
	height: 200px;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 0;
	padding-bottom: 0;
	padding-left: 12%;
	display: none;
	position: absolute;
	bottom: 5%;
}

.brochure-button {
	margin-top: 74px;
}

.brochure-button .secondary,
.brochure-button .secondary:hover {
	padding: 15px 15px;
	font-size: 1rem;
	font-family: minerva-modern, sans-serif;
	font-weight: 500;
	letter-spacing: 0.8px;
	background-color: #fff !important;
}

@media screen and (min-width: 1440px) {
	.lynx-block-hero-2 {
		margin-left: 11%;
	}

	.icon-wrapper {
		margin-top: 0;
	}

	.h3-headings.icon-headings {
		width: 80%;
		padding-left: 0;
	}

	.quick-links-wrapper {
		width: 75%;
		height: 200px;
		margin-top: -350px;
		padding-left: 11%;
	}

	.icon-link-wrapper {
		width: 90%;
		height: 200px;
		margin-right: 2%;
	}

	.resources-content {
		grid-column-gap: 32px;
	}

	.heading-6 {
		color: #047dbf;
		text-transform: uppercase;
		font-family: minerva-modern, sans-serif;
		font-weight: 400;
	}

	.uui-heading-medium-8.text-weight-medium {
		width: 90%;
		margin: 0 auto;
	}

	.uui-testimonial05_logo-wrapper {
		margin-bottom: 1rem;
	}

	.uui-testimonial05_slide {
		width: 100%;
	}

	.uui-container-large-11 {
		width: 51%;
		max-width: none;
	}

	.text-block-5 {
		font-size: 18px;
	}

	.section-hone {
		height: 88%;
	}

	.column {
		background-image: linear-gradient(#080808, rgba(0, 0, 0, 0) 20%), url('/20181016082423/assets/images/confetti_1confetti.png');
		background-position: 0 0, 50% 0;
		background-repeat: repeat, no-repeat;
		background-size: auto, 80%;
		border-right-color: #161616;
		position: relative;
	}

	.heading.clones {
		text-align: center;
		font-size: 140px;
	}

	.heading.followers {
		font-size: 140px;
	}

	.heading.tips {
		margin-bottom: 8px;
		font-size: 140px;
	}

	.main-section {
		margin-top: 94px;
	}

	.heading-1.inside-pages {
		width: 100%;
	}

	.dividel-footer {
		width: 23.5%;
	}

	.portfolio-section {
		margin-top: 0;
	}

	.large-card-background,
	.large-card-background-3,
	.large-card-background-2 {
		height: 30rem;
	}

	.button-with-arrow {
		width: 80%;
		height: 55px;
		padding-left: 20px;
		padding-right: 0;
	}

	.container-6.footer.gdrp {
		max-width: none;
	}

	.heading-2 {
		font-size: 50px;
	}

	.news-section {
		padding-top: 0;
	}

	.footer-2 {
		background-color: #047dbf;
		margin-top: 30px;
	}

	.text-span-white,
	.text-span-white-2 {
		color: #fff;
	}

	.enquire-now-footer {
		bottom: 120px;
	}

	.header-link.h1-link {
		color: #047dbf;
	}

	.header-link.h1-link:hover {
		color: #2a4187;
	}

	.button-6:hover {
		background-color: #047dbf;
	}

	.heading-3 {
		margin-top: 0;
		margin-bottom: 0.2rem;
		padding-top: 0.2rem;
		padding-bottom: 0.2rem;
	}

	.heading-4 {
		color: #047dbf;
		font-family: minerva-modern, sans-serif;
		font-weight: 400;
	}

	.right-col-item-wrap {
		border-top-color: #047dbf;
	}

	.right-col-link:hover {
		color: #2a4187;
	}

	.mobile-quick-links-wrapper {
		width: 75%;
		height: 200px;
		margin-top: -350px;
		padding-left: 11%;
	}
}

@media screen and (min-width: 1920px) {
	.lynx-block-hero-2 {
		width: 60%;
		margin-top: 100px;
		top: 220px;
	}

	.icon-wrapper {
		height: auto;
	}

	.lynx-subtitle-white-3 {
		display: none;
	}

	.h3-headings.icon-headings {
		width: 80%;
		margin-bottom: 0.5rem;
		padding-left: 0;
		font-size: 2.4rem;
		line-height: 2.6rem;
	}

	.quick-links-wrapper {
		width: 80%;
		height: auto;
		align-items: flex-end;
		margin-top: 0;
		margin-bottom: 0;
		top: auto;
		bottom: -1vw;
	}

	.icon-link-wrapper {
		width: 90%;
		height: 220px;
		align-items: flex-start;
		margin-right: 2%;
		padding-left: 20px;
		padding-right: 0%;
	}

	.div-block-17,
	.div-block-16,
	.text-block-8 {
		height: auto;
	}

	.slide-4 {
		background-position: 0 0, 50% 0;
		background-size: auto, cover;
	}

	.heading-1 {
		font-size: 50px;
	}

	.dividel-footer {
		width: 20%;
	}

	.icon-wrap-copy {
		width: 6rem;
	}

	.button-with-arrow {
		width: 80%;
		padding-left: 20px;
		padding-right: 0;
	}

	.section-2 {
		margin-top: 7%;
		margin-left: 2%;
		margin-right: 2%;
	}

	.container-6.footer.gdrp {
		max-width: none;
	}

	.button-3 {
		font-size: 16px;
	}

	.heading-2 {
		font-size: 50px;
	}

	.text-block-3 {
		font-size: 16px;
	}

	.padding-global {
		margin-bottom: 4rem;
	}

	.footer-2 {
		margin-top: 30px;
	}

	.lynx-block-hero-2-copy {
		margin-top: 100px;
	}

	.icon-link-wrapper-copy {
		width: 550px;
		height: auto;
		margin-right: 0%;
		padding-right: 0%;
	}

	.text-span-2-copy {
		margin-top: 0;
	}

	.cookie-text-para-2 {
		font-size: 15px;
	}

	.enquire-now-footer {
		bottom: 120px;
	}

	.div-block-17-contact,
	.div-block-17-copy {
		height: auto;
	}

	.mobile-quick-links-wrapper {
		width: 80%;
		height: auto;
		align-items: flex-end;
		margin-top: 0;
		margin-bottom: 0;
		top: auto;
		bottom: 5%;
	}
}

@media screen and (max-width: 991px) {
	.brochure-button {
		margin-top: 15px;
	}

	.text-span-5.fist-text.email-text {
		margin-top: 0px;
	}

	.flowbase-product-wrap {
		width: 85%;
		top: 14%;
	}

	.login-button-main {
		background-image: url('/20181016082423/assets/images/lock.svg');
		background-repeat: no-repeat;
		background-size: 20px;
		margin-right: 0;
		display: none;
	}

	.careers-link {
		justify-content: center;
		margin-right: 0;
		padding-right: 0;
	}

	.main-button {
		display: none;
	}

	.wrapper {
		justify-content: center;
	}

	.contact-us {
		transition: background-color 0.4s;
	}

	.top-nav {
		padding-left: 40px;
		padding-right: 61px;
		position: relative;
	}

	.div-block-21 {
		z-index: 115;
		height: 40px;
		background-color: #1a1a1a;
		flex-direction: row;
		padding-left: 5%;
		padding-right: 5%;
		display: none;
		position: fixed;
	}

	.search-bar {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		display: none;
	}

	.uui-navbar02_dropdown-content-left-2 {
		padding-left: 0;
		padding-right: 0;
	}

	.nav-link {
		width: 100%;
		color: #101828;
		justify-content: flex-start;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link:hover {
		color: #101828;
	}

	.menu-icon_line-middle {
		width: 24px;
		height: 2px;
		background-color: #fff;
		border-radius: 1rem;
		justify-content: center;
		align-items: center;
		margin-top: 6px;
		margin-bottom: 6px;
		padding-bottom: 0;
		padding-right: 0;
		display: flex;
	}

	.search-input-exit.two {
		padding-left: 0;
		padding-right: 0;
	}

	.uui-navbar02_dropdown-content-right-2 {
		max-width: 50rem;
		margin-bottom: 1rem;
	}

	.menu-icon_component-2 {
		width: 48px;
		height: 48px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-right: -0.5rem;
		padding-bottom: 0;
		padding-right: 0;
		display: flex;
	}

	.uui-navbar02_dropdown-toggle-2 {
		color: #101828;
		align-items: center;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 0;
		font-size: 1.125rem;
		display: flex;
	}

	.uui-navbar02_dropdown-toggle-2:hover {
		color: #101828;
	}

	.uui-navbar02_dropdown-toggle-2.w--open {
		height: auto;
		padding-bottom: 0.75rem;
	}

	.uui-navbar02_menu-2 {
		height: 100vh;
		-webkit-text-fill-color: inherit;
		background-color: #fff;
		background-clip: border-box;
		border-top: 1px solid #f2f4f7;
		align-items: flex-start;
		margin-left: 0;
		padding: 1.5rem 2rem 5rem;
		position: absolute;
		overflow: auto;
	}

	.dropdown-link {
		padding-left: 0;
		padding-right: 0;
	}

	.dropdown-link:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.uui-navbar02_menu-dropdown {
		width: 100%;
		margin-bottom: 0;
		position: relative;
	}

	.brand {
		margin-right: auto;
	}

	.uui-logo_component-5 {
		margin-top: 5px;
	}

	.uui-navbar02_dropdown-right-overlay-absolute {
		min-width: 100%;
		border-radius: 0.5rem;
	}

	.uui-navbar02_menu-left-2 {
		grid-column-gap: 0px;
		grid-row-gap: 0.5rem;
		color: #101828;
		flex-direction: column;
		align-items: flex-start;
		display: flex;
	}

	.search-input {
		padding-top: 1.55rem;
	}

	.menu-icon_line-bottom-3 {
		width: 24px;
		height: 2px;
		background-color: #fff;
		border-radius: 1rem;
		padding-bottom: 0;
		padding-right: 0;
	}

	.login-button-main-mobile {
		background-position: 20%;
		margin-right: 10px;
		font-size: 14px;
		font-weight: 500;
		display: none;
	}

	.uui-navbar02_container-2 {
		justify-content: flex-end;
	}

	.menu-icon_line-middle-inner-3 {
		width: 4px;
		height: 0;
		padding-bottom: 0;
		padding-right: 0;
	}

	.uui-navbar02_dropdown-content-2 {
		flex-direction: column;
	}

	.uui-dropdown-icon {
		color: #667085;
		margin-top: 0;
		margin-bottom: 0;
		margin-right: 0;
		top: auto;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.uui-navbar02_menu-button-3 {
		display: flex;
	}

	.uui-navbar02_menu-button-3.w--open {
		background-color: rgba(0, 0, 0, 0);
	}

	.uui-navbar02_dropdown-content-button-wrapper {
		margin-bottom: 0;
		margin-left: 0;
	}

	.uui-navbar02_dropdown-list-2 {
		position: relative;
		overflow: hidden;
	}

	.uui-navbar02_dropdown-list-2.w--open {
		min-width: auto;
		box-shadow: none;
		background-color: rgba(0, 0, 0, 0);
		border-style: none;
		align-items: flex-start;
		margin-bottom: 0;
		padding: 0 0 0;
		position: relative;
		top: 0;
		left: 0;
		right: 0;
	}

	.mega-menu {
		padding-left: 3%;
		padding-right: 3%;
		top: 0;
		bottom: auto;
		left: 0%;
		right: 0%;
	}

	.uui-navbar02_blog-content {
		max-width: none;
	}

	.menu-icon_line-top {
		width: 24px;
		height: 2px;
		background-color: #fff;
		border-radius: 1rem;
		padding-bottom: 0;
		padding-right: 0;
	}

	.search-icon {
		width: 40px;
		height: 40px;
		min-height: auto;
		background-size: 17px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.search-icon:hover {
		background-size: 17px;
	}

	.invisible-block {
		height: 80px;
		display: block;
	}

	.slider-container-2 {
		width: auto;
		height: 100vh;
		justify-content: flex-end;
	}

	.lynx-block-hero-2 {
		width: 60%;
		border-bottom-right-radius: 230px;
		align-items: flex-start;
		margin-top: 180px;
		margin-left: 0%;
		padding-left: 0;
		padding-right: 0;
		top: 5%;
		left: 4%;
	}

	.icon-wrapper {
		width: 100%;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		background-color: rgba(0, 0, 0, 0);
		border-top-style: none;
		border-bottom-style: none;
		flex-direction: row;
		grid-template-rows: auto auto;
		grid-template-columns: 40% 40%;
		grid-auto-columns: 1fr;
		justify-content: start;
		align-items: flex-start;
		margin-top: 0;
		display: grid;
	}

	.slide-2 {
		height: 500px;
	}

	.lynx-paragraph-white-4 {
		margin-top: 10px;
		margin-bottom: 0;
		font-size: 14px;
		line-height: 20px;
	}

	.slide-1 {
		height: auto;
	}

	.slider {
		height: 525px;
		top: auto;
	}

	.h3-headings {
		text-align: center;
		text-indent: 0;
		margin-top: 1rem;
		margin-bottom: 0;
		line-height: 2.25rem;
	}

	.h3-headings.icon-headings {
		width: 90%;
		margin-top: 20px;
		margin-bottom: 0.5rem;
		font-size: 1.5rem;
		line-height: 1.5rem;
	}

	.slide-nav-3 {
		flex-direction: row;
		margin-left: 5%;
		bottom: 40%;
	}

	.icon-wrap {
		width: 5.3rem;
		height: 3.4rem;
		margin-bottom: 0.5rem;
	}

	.bold-text-5 {
		font-size: 45px;
		line-height: 55px;
	}

	.main-button-apply {
		text-align: center;
		background-image: none;
		margin-top: 10px;
		margin-right: 0;
		padding-left: 25px;
	}

	.hero-div {
		height: auto;
		grid-template-columns: 1.25fr 1fr;
	}

	.bold-text-7 {
		font-size: 15px;
		line-height: 18px;
	}

	.quick-links-wrapper {
		width: 95%;
		height: 140px;
		align-items: center;
		margin-top: -110px;
		padding-bottom: 0;
		padding-left: 4%;
		bottom: 10%;
	}

	.icon-link-wrapper {
		width: 100%;
		height: 140px;
		flex-direction: column;
		align-items: flex-start;
		padding-top: 0;
		padding-left: 10px;
	}

	.lynx-heading-white-4 {
		font-size: 32px;
	}

	.lynx-heading-white-4.price {
		max-width: 748px;
		font-size: 70px;
		line-height: 35%;
	}

	.promo-slider {
		height: 442px;
		top: 119px;
	}

	.horiz-heading-underline {
		margin-bottom: 1.25rem;
	}

	.text-block {
		width: 500px;
		text-align: left;
	}

	.text-block.centered {
		text-align: center;
	}

	.resources-list-link {
		text-align: center;
		line-height: 1.25rem;
	}

	.heading-5 {
		font-size: 38px;
	}

	.resources-list-cb-link {
		align-items: center;
	}

	.services {
		margin-top: 119px;
		margin-bottom: 60px;
		padding-top: 0;
		top: 0;
	}

	.heading-wrapper {
		align-items: center;
	}

	.footer-section {
		padding-left: 0;
		padding-right: 0;
	}

	.link-block-2 {
		width: 64%;
	}

	.image-9 {
		width: 50%;
	}

	.div-block-17 {
		width: auto;
	}

	.column-3 {
		flex-direction: column;
		align-items: flex-start;
		padding-left: 57px;
		display: flex;
	}

	.columns {
		border-top-width: 0;
		padding-top: 0;
		padding-bottom: 0;
	}

	.footer-signoff-link-wrap {
		float: left;
		margin-bottom: 1.5rem;
		display: block;
	}

	.div-block-16 {
		width: 100%;
		grid-column-gap: 20px;
		grid-row-gap: 20px;
		flex-direction: column;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		align-items: center;
		margin-top: 0;
		display: grid;
	}

	.image-8 {
		width: 80%;
	}

	.footer-copyright {
		float: left;
		margin-bottom: 0.25rem;
		margin-right: 0;
	}

	.column-2 {
		flex-direction: column;
		align-items: flex-end;
		padding-right: 10px;
	}

	.footer-signoff-links {
		margin-left: 0;
		margin-right: 0.75rem;
	}

	.text-block-8 {
		width: 100%;
		height: 100%;
	}

	.heading-6 {
		font-size: 38px;
	}

	.uui-testimonial05_logo-wrapper {
		margin-bottom: 0;
	}

	.uui-container-large-11 {
		width: 90%;
	}

	.testimonias-one-by-one {
		padding-top: 2rem;
	}

	.uui-padding-vertical-xhuge-11 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.slide-3 {
		background-position: 0 0, 50%;
		background-repeat: repeat, no-repeat;
		background-size: auto, cover;
		background-attachment: scroll, scroll;
	}

	.slider-2 {
		height: 100vh;
		top: 95px;
	}

	.leptos-slider {
		height: 100vh;
	}

	.heading-7 {
		margin-top: 30px;
		font-size: 40px;
		line-height: 34px;
	}

	.h3 {
		font-size: 16px;
	}

	.counter {
		font-size: 30px;
	}

	.content-wrap-stats {
		padding-left: 0;
	}

	._1-3-grid {
		grid-row-gap: 25px;
		grid-template-columns: 1fr;
	}

	._1-3-grid._50-p {
		grid-column-gap: 8px;
		grid-template-columns: 1fr 1fr 1fr;
	}

	.main-section {
		margin-top: 14px;
	}

	.lynx-grid-image-left {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
	}

	.lynx-image {
		min-height: 450px;
	}

	.lynx-heading-2 {
		font-size: 38px;
	}

	.image-text-6x6 {
		padding-left: 2%;
		padding-right: 2%;
	}

	.heading-1 {
		font-size: 38px;
	}

	.heading-1.inside-pages {
		margin-top: 0;
		margin-bottom: 0;
		line-height: 2.5rem;
	}

	.heading-1.slider-heading {
		font-size: 50px;
		line-height: 50px;
	}

	._2-images-wrapper {
		margin-bottom: 0;
	}

	.about-us {
		margin-top: 160px;
	}

	.link-block-3 {
		display: none;
	}

	.link-block-3.secondary-button {
		display: block;
	}

	.center-block {
		align-items: flex-start;
	}

	.content-wrapper-center {
		max-width: 550px;
		margin-top: 30px;
		padding: 20px 40px 40px;
	}

	.dividel-footer {
		width: 37%;
		height: 35px;
	}

	.card-wrapper {
		margin: 0.5rem;
	}

	.large-card-background {
		width: 19rem;
		height: 16rem;
	}

	.large-card-header {
		font-size: 1.2rem;
	}

	.large-card-background-3,
	.large-card-background-2 {
		width: 19rem;
		height: 16rem;
	}

	.image-27 {
		height: 20px;
	}

	.counter-2,
	.counter-3 {
		font-size: 30px;
	}

	.nav-link-enquire {
		width: 100%;
		color: #101828;
		text-align: center;
		justify-content: center;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link-enquire:hover {
		color: #101828;
	}

	.link-block-4 {
		margin-top: 5px;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.studio-loft {
		max-height: 300px;
	}

	.text-block-6 {
		font-size: 100px;
		bottom: -6%;
	}

	.text-block-31 {
		font-size: 28px;
	}

	.loft-junghwa-dong,
	.poblenou-loft,
	.gym-loft {
		max-height: 300px;
	}

	.div-block-13 {
		width: 100%;
	}

	.tribeca-loft {
		max-height: 300px;
	}

	.grid-5 {
		height: 86%;
	}

	.candy-loft {
		max-height: 300px;
	}

	.div-block-14 {
		height: 80%;
	}

	.div-block-123 {
		width: 400px;
	}

	.heading-wrapper-2 {
		align-items: center;
	}

	.promo-container {
		width: 100%;
	}

	.div-block-124 {
		height: 68px;
		margin-top: 50px;
		margin-bottom: 40px;
	}

	.image-28 {
		bottom: 2px;
	}

	.icon-wrap-copy {
		width: 5.3rem;
		height: 3.4rem;
		width: 5.3rem;
		height: 3.4rem;
		width: 5.3rem;
		height: 3.4rem;
		margin-bottom: 0.5rem;
	}

	.link-text-2 {
		width: 100%;
		flex: none;
	}

	.button-with-arrow {
		width: 90%;
	}

	.text-cta {
		width: auto;
		font-size: 12px;
	}

	.div-block-125 {
		width: 100%;
		background-color: rgba(29, 29, 27, 0.3);
		padding-top: 10px;
	}

	.content-wrap-stats-copy {
		padding-left: 20px;
	}

	.left-arrow-5,
	.right-arrow-5 {
		display: none;
	}

	.slider-graphic-copy {
		grid-column-gap: 68px;
		background-image: none;
		background-position: 0 0;
		background-repeat: repeat;
		background-size: auto;
		padding-bottom: 20px;
		padding-left: 40px;
		padding-right: 20px;
	}

	.heading-4-copy {
		font-size: 32px;
		line-height: 42px;
	}

	.account-login-wrapper-copy {
		max-height: 350px;
		max-width: 350px;
		margin: 70px 20px 0 0;
	}

	.online-banking-heading-copy {
		text-align: left;
		font-size: 18px;
		line-height: 35px;
	}

	.text-block-5-copy {
		font-size: 16px;
		line-height: 22px;
	}

	.login-button {
		margin-bottom: 1rem;
	}

	.div-block-26 {
		flex-wrap: wrap;
		margin-top: 5px;
		margin-bottom: 0;
	}

	.right-arrow-copy {
		z-index: 30;
		display: none;
	}

	.slide-nav-copy {
		z-index: 20;
	}

	.slide-copy {
		background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), none;
		background-position: 0 0, 95%;
		background-repeat: repeat, no-repeat;
		background-size: auto, cover;
	}

	.slider-2-copy-copy {
		height: 500px;
		justify-content: center;
		align-items: flex-end;
		display: flex;
	}

	.slide-link.enroll.padding-10 {
		padding-top: 5px;
	}

	.left-arrow-copy {
		z-index: 30;
		height: 50px;
		display: none;
	}

	.slide-2-copy {
		background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), none;
		background-position: 0 0, 50%;
		background-repeat: repeat, repeat;
		background-size: auto, auto;
	}

	.gdrp-link-copy {
		margin-right: 1rem;
	}

	.home-content-footer {
		background-image: linear-gradient(to bottom, null, null), url('/20181016082423/assets/images/pattern-1.png');
		background-repeat: repeat, repeat-y;
	}

	.button-link-2 {
		margin-top: 20px;
		font-size: 11px;
		position: static;
	}

	.exit-icon-div {
		right: 1rem;
	}

	.cookie-div {
		width: 90%;
		justify-content: center;
	}

	.container-6.footer.gdrp {
		flex-direction: column;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.cookie-text-para {
		width: 90%;
		margin-bottom: 0.25rem;
		font-size: 0.75rem;
		line-height: 1.25rem;
	}

	.open-gdrp-icon {
		margin-right: 0.5rem;
	}

	.gdrp-section-icon.just-icon {
		width: 100px;
		justify-content: center;
	}

	.gdrp-link {
		margin-right: 1rem;
	}

	.button-underline {
		margin-top: 5px;
		margin-left: -27px;
	}

	.footer-signoff-links-copy {
		margin-left: 0;
		margin-right: 0.75rem;
	}

	.padding-section-medium {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.heading-2 {
		font-size: 26px;
		line-height: 30px;
	}

	.news-section {
		padding-top: 40px;
		padding-left: 40px;
		padding-right: 40px;
	}

	.div-block-5 {
		height: auto;
	}

	.footer-2 {
		padding-left: 0;
		padding-right: 0;
	}

	.padding-bottom {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.footer-social-icon-link-2 {
		width: 1.8rem;
		height: 1.8rem;
	}

	.div-block-130 {
		align-items: flex-start;
	}

	.link-list-title-2 {
		margin-bottom: 1rem;
		font-family: Montserrat, sans-serif;
	}

	.text-block-8-copy {
		width: 100%;
		width: 100%;
	}

	.div-block-17-copy {
		width: auto;
		padding-left: 20px;
	}

	.image-30,
	.image-31 {
		height: 20px;
	}

	.slide-nav-4 {
		bottom: 0;
	}

	.uui-padding-vertical-xhuge-7 {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}

	.two-box-promo {
		grid-template-columns: 1fr 1fr;
	}

	.text-cta-3 {
		width: auto;
		font-size: 12px;
	}

	.button-with-arrow-copy {
		width: 87%;
	}

	.nav-link-out {
		width: 100%;
		color: #101828;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link-out:hover {
		color: #101828;
	}

	.button-with-arrow-copy-copy,
	.button-with-arrow-services {
		width: 87%;
	}

	.text-cta-services {
		width: 100%;
		font-size: 12px;
	}

	.lynx-block-hero-2-copy {
		width: 50%;
		border-bottom-right-radius: 230px;
		margin-left: 0%;
		padding-left: 0;
		padding-right: 0;
		top: 5%;
		left: 4%;
	}

	.icon-link-wrapper-copy {
		width: 30ch;
		height: 155px;
		flex-direction: column;
		padding-top: 0;
	}

	.mask-6 {
		height: 100vh;
	}

	.footer-signoff-links-copy {
		margin-left: 0;
		margin-right: 0.75rem;
	}

	.uui-navbar02_item-heading-2-copy {
		color: #1d1d1b;
		font-size: 15px;
	}

	.gdrp-link-copy-2 {
		margin-right: 1rem;
	}

	.open-gdrp-icon-2 {
		margin-right: 0;
	}

	.home-content-footer-2 {
		background-image: linear-gradient(to bottom, null, null), url('/20181016082423/assets/images/pattern-1.png');
		background-repeat: repeat, repeat-y;
	}

	.cookie-text-para-2 {
		width: 90%;
		margin-bottom: 0.25rem;
		font-size: 0.75rem;
		line-height: 1.25rem;
	}

	.gdrp-link-2 {
		margin-right: 1rem;
	}

	.enquire-now-footer {
		width: 100px;
		justify-content: center;
	}

	.link-block-5 {
		margin-right: 0;
	}

	.ui-nav-bar-container {
		background-color: rgba(0, 0, 0, 0);
	}

	.heading-16 {
		font-size: 30px;
	}

	.left-nav-list-2 {
		margin-bottom: 1.5em;
	}

	.content-wrapper {
		flex-direction: column;
		position: relative;
	}

	.left-nav-col-2 {
		display: none;
	}

	.main-content-wrapper {
		width: 100%;
		padding-top: 0;
	}

	.inside-page-content-wrap-no-sidebars-2 {
		margin-left: 0%;
		margin-right: 0%;
	}

	.left-nav-wrapper-2 {
		height: 0;
		overflow: hidden;
	}

	.heading-17 {
		font-size: 30px;
		line-height: 34px;
	}

	.heading-18 {
		font-size: 28px;
	}

	.inside-content-wrapper-2 {
		padding-bottom: 1rem;
	}

	.heading-3 {
		font-size: 22px;
		line-height: 24px;
	}

	.heading-4 {
		font-size: 20px;
	}

	.right-col-item-wrap {
		margin-left: 0;
	}

	.heading-25 {
		font-size: 28px;
	}

	.slide-3-copy {
		background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/20181016082423/assets/images/management1.jpg');
		background-position: 0 0, 76%;
		background-repeat: repeat, no-repeat;
		background-size: auto, cover;
		background-attachment: scroll, scroll;
	}

	.about-us-copy {
		margin-top: 160px;
	}

	.div-block-17-contact,
	.div-block-17-copy {
		width: auto;
		height: 100%;
	}

	.mobile-quick-links-wrapper {
		width: 95%;
		height: 140px;
		align-items: center;
		margin-top: -110px;
		padding-bottom: 0;
		padding-left: 4%;
		bottom: 18%;
	}
}

@media screen and (max-width: 767px) {
	.text-span-5.fist-text {
		margin-top: 0 !important;
	}

	.flowbase-product-wrap {
		display: none;
	}

	.login-button-main {
		font-size: 12px;
		display: none;
	}

	.main-button {
		z-index: 460;
		background-color: rgba(0, 0, 0, 0);
		margin-top: -1px;
		margin-bottom: 1px;
		margin-right: 50px;
		position: relative;
		top: auto;
		bottom: 0%;
		left: 0%;
		right: 0%;
	}

	.location-link {
		margin-right: 0;
		padding-right: 0;
	}

	.wrapper {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		justify-content: center;
	}

	.contact-us {
		margin-right: 0;
		padding-right: 0;
	}

	.link-text {
		font-size: 0.85rem;
	}

	.top-nav {
		padding-left: 10px;
		padding-right: 10px;
		position: relative;
	}

	.div-block-21 {
		margin-bottom: 0;
		padding-top: 10px;
		padding-bottom: 10px;
		position: fixed;
	}

	.search-2 {
		z-index: 460;
		margin-top: 20px;
		position: relative;
	}

	.search-bar {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.uui-button-row {
		align-self: stretch;
	}

	.uui-button-link-gray-2 {
		font-size: 1rem;
	}

	.uui-button-link-gray-2:hover {
		color: #475467;
	}

	.uui-navbar02_dropdown-content-left-2 {
		grid-row-gap: 1.5rem;
		flex-direction: column;
		grid-template-rows: auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.nav-link {
		font-size: 1rem;
	}

	.uui-navbar02_blog-item {
		padding: 0;
	}

	.uui-navbar02_blog-item:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.uui-button-link {
		font-size: 1rem;
	}

	.uui-button-link:hover {
		color: #6941c6;
	}

	.uui-navbar02_dropdown-content-right-2 {
		padding: 2rem 1rem;
	}

	.uui-navbar02_dropdown-toggle-2 {
		font-size: 1rem;
	}

	.uui-navbar02_menu-2 {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.login-button-main-mobile {
		font-size: 12px;
		display: none;
	}

	.uui-navbar02_dropdown-link-list {
		max-width: none;
		grid-row-gap: 0.5rem;
	}

	.mega-menu {
		z-index: 2000;
		min-height: 4.5rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.search-icon {
		width: 40px;
		height: 40px;
		background-size: 17px;
	}

	.slider-container-2 {
		width: 100%;
		text-align: center;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		display: flex;
	}

	.lynx-block-hero-2 {
		width: 90%;
		align-items: flex-start;
		margin-top: 100px;
		margin-left: 0;
		padding-top: 20px;
		padding-left: 0;
		padding-right: 0;
		position: static;
		top: 0;
	}

	.icon-wrapper {
		width: 100%;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-direction: row;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		justify-content: space-between;
		margin-left: 1%;
		display: grid;
		overflow: hidden;
	}

	.lynx-paragraph-white-4 {
		max-width: none;
		margin-top: 0;
	}

	.slider {
		height: 450px;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		display: flex;
	}

	.mask-5 {
		position: static;
		top: 0;
	}

	.h3-headings {
		margin-top: 0;
		margin-bottom: 0.5rem;
	}

	.h3-headings.icon-headings {
		margin-top: 0;
		margin-bottom: 0.5rem;
		font-size: 20px;
		line-height: 1.1rem;
	}

	.slide-nav-3 {
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		padding-right: 0;
		bottom: 35%;
		left: auto;
	}

	.icon-wrap {
		width: 5.3rem;
		height: 4.5rem;
		margin-bottom: 0;
	}

	.main-button-apply {
		font-size: 12px;
		display: block;
	}

	.main-button-apply.dark-background {
		display: none;
	}

	.hero-div {
		flex-direction: column;
		grid-template-columns: 1.25fr;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.quick-links-wrapper {
		width: 95%;
		flex-direction: row;
		justify-content: center;
		margin-top: 0;
		padding-bottom: 0;
		position: absolute;
		top: auto;
		bottom: 14%;
	}

	.icon-link-wrapper {
		width: 90%;
		flex-direction: column;
		margin-bottom: 0;
		margin-right: 1%;
		padding-left: 0.6rem;
		padding-right: 0.6rem;
		overflow: visible;
	}

	.lynx-heading-white-4 {
		font-size: 40px;
	}

	.promo-slider {
		top: 110px;
	}

	.resources-content {
		grid-template-columns: 1fr 1fr;
		justify-items: center;
	}

	.horiz-heading-underline {
		margin-bottom: 1rem;
	}

	.text-block {
		width: auto;
		max-width: none;
		margin-bottom: 1.5rem;
	}

	.text-block.centered {
		width: 60%;
		margin-bottom: 1.5rem;
	}

	.resources-list-link {
		margin-bottom: 0;
		font-size: 0.9rem;
		line-height: 1rem;
	}

	.resources-grid {
		width: 100%;
	}

	.heading-5 {
		margin-bottom: 20px;
		font-size: 32px;
	}

	.resources-list-cb-link {
		width: 150px;
		height: 150px;
		flex-direction: column;
		align-items: center;
		padding-bottom: 1rem;
		padding-left: 10px;
		padding-right: 10px;
	}

	.services {
		margin-bottom: 140px;
	}

	.footer-section {
		padding-top: 0;
		position: relative;
	}

	.link-block-2 {
		width: 98%;
		margin-bottom: 20px;
	}

	.image-9 {
		width: 25%;
		height: auto;
	}

	.div-block-17 {
		flex-direction: column;
		justify-content: center;
	}

	.column-3 {
		align-items: center;
		padding-left: 0;
		overflow: hidden;
	}

	.footer-grip-link.white-logo {
		width: 133px;
	}

	.columns {
		flex-wrap: wrap;
	}

	.footer-signoff-link-wrap {
		float: none;
		margin-bottom: 0.5rem;
		display: inline-block;
	}

	.div-block-16 {
		flex-wrap: wrap;
		justify-content: center;
		align-items: start;
	}

	.image-8 {
		width: 25%;
		height: auto;
	}

	.div-block-18 {
		width: 40%;
		justify-content: center;
		margin-top: 20px;
	}

	.footer-copyright {
		float: none;
	}

	.column-2 {
		flex-direction: row;
		justify-content: center;
		padding-right: 0;
	}

	.text-block-8 {
		width: auto;
		text-align: center;
		border-left-style: none;
		margin-top: 20px;
		margin-left: 0;
		padding-left: 0;
		font-size: 13px;
	}

	.uui-page-padding-11 {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.heading-6 {
		font-size: 32px;
	}

	.uui-testimonial05_arrow:hover {
		background-color: #fff;
	}

	.uui-heading-medium-8 {
		font-size: 1.75rem;
		line-height: 1.4;
	}

	.uui-testimonial05_component {
		padding-bottom: 3rem;
	}

	.uui-testimonial05_slide {
		padding-left: 0;
		padding-right: 0;
	}

	.uui-padding-vertical-xhuge-11 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.slider-2 {
		height: 100vh;
		background-color: rgba(0, 0, 0, 0);
	}

	.leptos-slider {
		height: 100vh;
	}

	.heading-7,
	.paragraph-2 {
		text-align: left;
	}

	._3-columns {
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		grid-template-rows: auto auto auto;
		grid-template-columns: 1fr;
	}

	.section-home {
		height: 68%;
		align-items: center;
		padding-top: 0;
		padding-bottom: 0;
		overflow: auto;
	}

	.column {
		height: 100%;
		background-image: none;
		background-position: 0 0;
		border-bottom: 1px solid #282729;
		border-right-style: none;
		flex-direction: column;
		padding-top: 16px;
		padding-bottom: 16px;
	}

	.heading,
	.heading.tips {
		font-size: 100px;
	}

	.lynx-grid-image-left {
		grid-row-gap: 60px;
		grid-template-columns: 1fr;
	}

	.lynx-heading-2 {
		max-width: 540px;
		font-size: 34px;
	}

	.lynx-block-right {
		padding-left: 5%;
		padding-right: 5%;
	}

	.text-block-30.text-block-color {
		font-size: 0.9rem;
	}

	.heading-1 {
		font-size: 32px;
	}

	.heading-1.slider-heading {
		text-align: left;
		font-size: 40px;
		line-height: 44px;
	}

	.about-us {
		margin-top: 160px;
	}

	.paragraph-7 {
		width: 90%;
	}

	.small-image {
		width: 30%;
	}

	.big-image {
		width: 50%;
	}

	.content-wrapper-center {
		width: 85%;
		max-width: 500px;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.text-span.contact-links {
		font-size: 16px;
	}

	.text-span-2 {
		margin-top: 22px;
	}

	.text-span-4 {
		margin-bottom: 10px;
	}

	.div-block-120 {
		justify-content: center;
		padding-left: 0%;
	}

	.dividel-footer {
		width: 30%;
		justify-content: center;
		margin-left: auto;
	}

	.large-card-section {
		padding-left: 3%;
		padding-right: 3%;
	}

	.card-wrapper {
		font-size: 14px;
		line-height: 24px;
	}

	.large-card-background {
		width: 32rem;
		height: 16rem;
		margin: 0;
		font-size: 14px;
		line-height: 24px;
	}

	.large-card-text-wrap {
		font-size: 14px;
		line-height: 24px;
	}

	.large-card-background-3,
	.large-card-background-2 {
		width: 32rem;
		height: 16rem;
		margin: 0;
		font-size: 14px;
		line-height: 24px;
	}

	.nav-link-enquire {
		font-size: 1rem;
	}

	.icon-wrap-copy {
		width: 5.3rem;
		height: 4.5rem;
		width: auto;
		height: 4.5rem;
		width: 5.3rem;
		height: 4.5rem;
		margin-bottom: 0;
	}

	.button-with-arrow {
		width: 100%;
		height: 40px;
	}

	.block-arrow {
		width: 20px;
	}

	.slider-learn-more-copy {
		margin-bottom: 0;
	}

	.div-block-23-copy {
		width: 100%;
		background-color: rgba(0, 0, 0, 0);
	}

	.slider-graphic-copy {
		z-index: 410;
		background-position: 0 62%;
		background-size: 750px;
		padding-bottom: 40px;
		padding-right: 140px;
		position: relative;
	}

	.heading-4-copy {
		font-size: 35px;
		line-height: 40px;
	}

	.account-login-wrapper-copy {
		width: 276px;
		height: 64%;
		max-height: none;
		max-width: none;
		grid-column-gap: 15px;
		grid-row-gap: 15px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		align-items: center;
		justify-items: center;
		margin-top: 102px;
		display: flex;
		overflow: hidden;
	}

	.online-banking-heading-copy {
		font-size: 15px;
		line-height: 40px;
	}

	.text-block-5-copy {
		font-size: 15px;
		line-height: 20px;
	}

	.slide-nav-copy {
		height: 50px;
	}

	.slider-2-copy-copy {
		height: 450px;
		position: relative;
	}

	.exit-icon-div {
		width: 20px;
		height: 20px;
	}

	.cookie-div {
		width: 85%;
		justify-content: flex-start;
		padding-left: 10px;
		padding-right: 10px;
	}

	.container-6 {
		padding-left: 10px;
		padding-right: 10px;
	}

	.cookie-text-para {
		width: 85%;
	}

	.gdrp-section-icon.just-icon {
		width: 100px;
		justify-content: center;
	}

	.button-underline {
		margin-top: 3px;
		margin-left: -27px;
	}

	.button-underline.gdpr {
		margin-left: 40px;
	}

	.gdrp-section {
		display: none;
	}

	.padding-section-medium {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.features_grid {
		grid-template-columns: 1fr;
	}

	.div-block-2 {
		width: 80%;
	}

	.news-section {
		z-index: 500;
		background-color: #fff;
		flex-wrap: wrap;
		margin-top: 95px;
		padding-top: 20px;
		padding-bottom: 20px;
		position: relative;
	}

	.div-block-12 {
		flex-wrap: wrap;
		justify-content: center;
	}

	.features_components {
		grid-row-gap: 2rem;
	}

	.div-block-6 {
		margin-bottom: 0;
	}

	.features_header {
		grid-row-gap: 2rem;
		grid-template-columns: 1fr;
	}

	.padding-global {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.footer-2 {
		padding-top: 0;
		position: relative;
	}

	.padding-bottom {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.footer-social-icon-link-2 {
		margin-left: 0;
		margin-right: 1rem;
	}

	.div-block-130 {
		flex-direction: column;
		justify-content: center;
	}

	.brand-logo-footer {
		margin-top: 15px;
	}

	.text-block-8-copy {
		width: auto;
		text-align: center;
		border-left-style: none;
		margin-top: 20px;
		margin-left: 0;
		padding-left: 0;
		font-size: 13px;
	}

	.contact-div {
		flex-direction: column;
		justify-content: center;
	}

	.text-block-8-copy {
		width: auto;
		text-align: center;
		border-left-style: none;
		margin-top: 20px;
		margin-left: 0;
		padding-left: 0;
		font-size: 13px;
	}

	.div-block-17-copy-copy,
	.quick-links-div,
	.div-block-17-copy,
	.div-block-17-copy,
	.div-block-17-copy {
		flex-direction: column;
		justify-content: center;
	}

	.uui-space-xsmall-4 {
		min-height: 0.75rem;
	}

	.uui-contact02_component {
		margin-top: 3rem;
	}

	.uui-button-7 {
		font-size: 1rem;
	}

	.uui-button-7:hover {
		background-color: #0871b9;
		border-color: #0871b9;
	}

	.uui-padding-vertical-xhuge-7 {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.uui-text-size-large-5 {
		font-size: 1rem;
	}

	.two-box-promo {
		width: 80%;
		max-width: none;
		grid-template-columns: 1fr;
	}

	.team_image {
		width: 100%;
		height: 18rem;
	}

	.heading-13 {
		font-size: 32px;
		line-height: 40px;
	}

	.team_content-2 {
		right: 15%;
	}

	.team_name-copy-2 {
		font-size: 1.25rem;
	}

	.nav-link-out {
		font-size: 1rem;
	}

	.button-with-arrow-services {
		width: 75%;
	}

	.text-cta-services {
		width: auto;
	}

	.lynx-block-hero-2-copy {
		width: 90%;
		align-items: center;
		margin-top: 30px;
		margin-left: 0;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		position: static;
		top: 0;
	}

	.icon-link-wrapper-copy {
		width: 25ch;
		flex-direction: column;
		margin-bottom: 0;
		padding-left: 0.6rem;
		padding-right: 0.6rem;
	}

	.gdrp-section-2 {
		display: none;
	}

	.open-gdrp-icon-2 {
		margin-right: 0;
	}

	.cookie-text-para-2 {
		width: 85%;
	}

	.enquire-now-footer {
		width: 100px;
		justify-content: center;
	}

	.link-block-5 {
		margin-right: 0;
	}

	.breadcrumb-list {
		font-size: 0.6875rem;
	}

	.main-content-wrapper {
		padding-top: 0;
		overflow: visible;
	}

	.main-content-wrapper.no-sidebars {
		margin-left: 10px;
		margin-right: 10px;
	}

	.heading-4 {
		margin-bottom: 0;
	}

	.right-col-item-wrap {
		margin-left: 0;
	}

	.about-us-copy {
		margin-top: 160px;
	}

	.div-block-17-contact,
	.div-block-17-copy {
		flex-direction: column;
		justify-content: center;
	}

	.mobile-quick-links-wrapper {
		width: 95%;
		flex-direction: row;
		justify-content: center;
		margin-top: 0;
		padding-bottom: 0;
		position: absolute;
		top: auto;
		bottom: 24%;
	}
}

@media screen and (max-width: 479px) {
	.h3 {
		text-align: left;
	}

	.plus-icon {
		justify-content: flex-start;
	}

	.flowbase-close-card {
		top: 0;
		bottom: auto;
		right: 0;
	}

	.flowbase-product-wrap {
		flex-direction: column;
		align-items: flex-start;
		padding-left: 10px;
		padding-right: 10px;
	}

	.flowbase-title-wrap {
		width: 100%;
		padding-left: 4px;
	}

	.flowbase-icon-wrap {
		min-width: 60px;
	}

	.login-button-main {
		width: 136px;
		background-size: 15px;
		margin-top: 8px;
		padding: 5px 15px 5px 35px;
		font-size: 11px;
		display: block;
		position: absolute;
		top: 0%;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.search-button {
		width: 30px;
		height: 30px;
	}

	.careers-link {
		padding-right: 0;
	}

	.main-button {
		margin-top: 0;
		margin-bottom: 5px;
		margin-right: 0;
		display: none;
	}

	.location-link {
		justify-content: center;
		margin-right: 0;
		padding-right: 0;
	}

	.contact-us {
		margin-left: 0.75rem;
		margin-right: 0.75rem;
	}

	.link-text {
		opacity: 1;
		font-size: 0.85rem;
		line-height: 1rem;
	}

	.top-nav {
		padding-top: 10px;
		padding-bottom: 0;
	}

	.search-input-2 {
		height: 30px;
		margin-right: -30px;
	}

	.div-block-21 {
		justify-content: space-between;
		padding-top: 10px;
		padding-bottom: 10px;
		display: none;
	}

	.search-2 {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		justify-content: center;
		margin-top: 0;
		margin-bottom: 0;
		display: none;
	}

	.search-bar {
		width: 90%;
		top: 81px;
	}

	.uui-navbar02_dropdown-content-left-2 {
		grid-template-columns: 1fr;
		padding-top: 0;
		padding-bottom: 0;
	}

	.uui-navbar02_blog-item {
		flex-direction: column;
		display: flex;
	}

	.search {
		margin-right: 0.5rem;
	}

	.uui-navbar02_dropdown-content-right-2 {
		max-width: none;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.menu-icon_component-2 {
		margin-right: -0.9rem;
	}

	.dropdown-link {
		padding-left: 0;
	}

	.brand {
		width: 60%;
	}

	.uui-logo_component-5 {
		height: auto;
	}

	.uui-navbar02_dropdown-content-wrapper {
		width: 100%;
	}

	.brand-logo {
		width: 100%;
		object-fit: fill;
	}

	.login-button-main-mobile {
		margin-top: 0;
		margin-right: 0;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 35px;
		font-size: 11px;
		display: none;
	}

	.uui-navbar02_container-2 {
		width: 100%;
	}

	.mega-menu {
		z-index: 2000;
		height: 80px;
		min-height: 4.8rem;
		top: 0;
	}

	.uui-navbar02_blog-content {
		margin-top: 0.5rem;
	}

	.uui-navbar02_dropdown-blog-item-wrapper {
		grid-column-gap: 0px;
		grid-row-gap: 0.5rem;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
	}

	.search-icon {
		width: 35px;
		height: 35px;
		background-size: 13px;
		margin-left: 10px;
	}

	.invisible-block {
		display: block;
	}

	.slider-container-2 {
		width: auto;
		height: auto;
		margin-top: 60px;
		margin-bottom: 40px;
	}

	.lynx-block-hero-2 {
		width: 90%;
		text-align: center;
		border-bottom-right-radius: 30px;
		justify-content: center;
		align-items: flex-start;
		margin-top: 0;
		margin-left: 0;
		padding-top: 0;
		position: static;
		top: 0;
	}

	.icon-wrapper {
		width: 100%;
		max-width: none;
		grid-column-gap: 0px;
		grid-row-gap: 20px;
		flex-direction: row;
		grid-template-rows: auto auto auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		justify-content: center;
		align-items: start;
		justify-items: start;
		margin-top: 0;
		margin-left: 5%;
		display: grid;
	}

	.slide-2 {
		height: auto;
		margin-bottom: 20px;
	}

	.lynx-paragraph-white-4 {
		margin-top: 0;
		margin-bottom: 10px;
		font-size: 12px;
	}

	.slider {
		height: auto;
	}

	.mask-5 {
		padding-bottom: 0;
		padding-left: 10px;
		padding-right: 10px;
	}

	.image-home {
		width: 0.65rem;
		height: 0.65rem;
	}

	.h3-headings {
		font-size: 0.9rem;
		line-height: 2.2rem;
	}

	.h3-headings.icon-headings {
		letter-spacing: 0;
		margin-bottom: 0;
		font-size: 1.2rem;
	}

	.slide-nav-3 {
		justify-content: center;
		padding-top: 0;
		padding-right: 0;
		position: static;
		left: auto;
		right: auto;
	}

	.icon-wrap {
		width: 5.7rem;
		height: 4.7rem;
		align-items: flex-end;
	}

	.bold-text-5 {
		font-size: 28px;
		line-height: 19px;
	}

	.main-button-apply {
		background-image: none;
		margin-top: 10px;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-size: 11px;
		display: block;
	}

	.main-button-apply.dark-background {
		width: auto;
		padding-left: 15px;
		padding-right: 15px;
		display: none;
		position: relative;
	}

	.hero-div {
		grid-template-columns: 1.25fr;
		margin-top: 80px;
	}

	.quick-links-wrapper {
		z-index: 100;
		height: auto;
		flex-direction: row;
		margin-top: 0;
		padding-left: 0%;
		display: none;
		position: absolute;
		top: auto;
		bottom: -12%;
	}

	.icon-img {
		display: block;
	}

	.icon-img:hover {
		background-image: url('/20181016082423/assets/images/slider-arrow-new-orange.svg');
		background-position: 50%;
		background-size: auto;
	}

	.icon-link-wrapper {
		width: 70%;
		height: 70px;
		flex-direction: row;
		align-items: center;
		margin-right: 0%;
		padding-left: 1.2rem;
	}

	.lynx-heading-white-4 {
		width: 94%;
		margin-bottom: 10px;
		font-size: 16px;
	}

	.lynx-heading-white-4.price {
		font-size: 30px;
		line-height: 85%;
	}

	.promo-slider {
		height: 560px;
		flex-direction: column;
		align-items: center;
		padding-top: 0;
		display: flex;
		top: 117px;
	}

	.resources-content {
		grid-column-gap: 16px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.text-block {
		max-width: 350px;
		text-align: center;
		margin-bottom: 2rem;
		font-size: 0.9rem;
		line-height: 1.25rem;
	}

	.text-block.centered {
		width: 85%;
		max-width: none;
		margin-bottom: 2rem;
		padding-left: 0;
		padding-right: 0;
	}

	.resources-list-link {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		font-size: 0.8rem;
		line-height: 1.2rem;
	}

	.resources-grid {
		margin-left: 0;
		margin-right: 0;
	}

	.heading-5 {
		margin-top: 0;
		margin-bottom: 20px;
		font-size: 30px;
	}

	.resources-list-cb-link {
		width: 100%;
		height: 150px;
		justify-content: center;
		padding: 0.5rem 0.75rem;
	}

	.services {
		margin-left: 0;
		margin-right: 0;
		padding-top: 60px;
		padding-left: 0%;
		padding-right: 0;
	}

	.heading-wrapper {
		margin-bottom: 0;
	}

	.resources-content-section {
		width: auto;
		max-width: none;
		margin-left: 0;
		margin-right: 0;
	}

	.footer-signoff-link-2 {
		margin-right: 0%;
	}

	.footer-section {
		z-index: 0;
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;
		top: 0;
	}

	.link-block-2 {
		width: 70%;
		margin-bottom: 20px;
		margin-right: 0;
	}

	.image-9 {
		width: 40%;
		height: auto;
	}

	.div-block-17 {
		width: 100%;
		border-bottom: 1px solid #047dbf;
		align-items: center;
		padding-bottom: 0;
		padding-left: 0;
	}

	.column-3 {
		margin-bottom: 0;
		padding-left: 0;
	}

	.footer-grip-link.white-logo {
		margin-bottom: 10px;
	}

	.div-block-15 {
		padding-top: 0;
	}

	.columns {
		flex-wrap: wrap;
	}

	.footer-signoff-link-wrap {
		width: auto;
		text-align: center;
		justify-content: space-between;
		padding-top: 0;
		display: flex;
	}

	.div-block-16 {
		grid-template-columns: 1fr;
	}

	.image-8 {
		width: 28%;
		height: auto;
	}

	.div-block-18 {
		justify-content: center;
		margin-top: 0;
	}

	.footer-copyright {
		width: 80%;
		text-align: center;
		margin-bottom: 0.5rem;
		font-size: 0.9rem;
	}

	.column-2 {
		flex-direction: column;
		align-items: center;
		padding-left: 0;
	}

	.footer-signoff-links {
		margin-right: 10px;
	}

	.footer-signoff-links.last {
		display: flex;
	}

	.text-block-8 {
		width: auto;
		height: auto;
		text-align: center;
		margin-top: 0;
		font-size: 13px;
	}

	.heading-6 {
		font-size: 28px;
	}

	.uui-heading-medium-8.text-weight-medium {
		font-size: 14px;
		line-height: 1.9;
	}

	.uui-testimonial05_logo-wrapper {
		margin-bottom: 0;
	}

	.uui-testimonial05_content {
		width: 98%;
	}

	.uui-padding-vertical-xhuge-11 {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.slide-3 {
		background-position: 0 0, 38%;
		background-size: auto, cover;
	}

	.slider-2 {
		height: 700px;
		top: 95px;
	}

	.leptos-slider {
		height: 700px;
	}

	.heading-7 {
		margin-top: 0;
		font-size: 28px;
		line-height: 29px;
	}

	.text-block-5 {
		font-size: 14px;
	}

	.section-home {
		height: 72%;
		overflow: auto;
	}

	.image-2 {
		height: 16px;
		margin-right: 8px;
	}

	.column {
		background-image: none;
		background-position: 0 0;
		background-size: auto;
	}

	.heading,
	.heading.tips {
		font-size: 80px;
	}

	.spacer-blank {
		height: auto;
	}

	.promo-counter {
		margin-top: 73px;
	}

	.paragraph-3 {
		font-size: 16px;
	}

	.main-container {
		max-width: none;
		align-items: center;
	}

	.h2 {
		font-size: 30px;
	}

	.content-wrap-stats {
		grid-row-gap: 6px;
	}

	._1-3-grid._50-p {
		grid-template-columns: 1fr;
	}

	.paragraph-3-copy {
		font-size: 16px;
	}

	.div-block-33 {
		justify-content: center;
		display: flex;
	}

	.lynx-heading-2 {
		font-size: 30px;
	}

	.image-text-6x6 {
		margin-top: 60px;
		padding-top: 40px;
	}

	.lynx-button {
		float: none;
		clear: none;
		text-align: center;
	}

	.heading-1 {
		width: 100%;
		margin-top: 0;
		font-size: 30px;
	}

	.heading-1.inside-pages {
		font-size: 28px;
		line-height: 1.8rem;
	}

	.heading-1.slider-heading {
		font-size: 25px;
		line-height: 27px;
	}

	._2-images-wrapper {
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
	}

	.about-us {
		margin-top: 140px;
	}

	.image-11 {
		height: auto;
	}

	.paragraph-7 {
		width: 100%;
	}

	.center-block {
		position: relative;
	}

	.small-image {
		width: 100%;
		height: auto;
		justify-content: flex-end;
		margin-top: 10px;
	}

	.image-26 {
		width: auto;
		height: 61vw;
		max-width: none;
		display: none;
	}

	.big-image {
		width: 100%;
		height: 110vw;
		margin-top: -60px;
		margin-bottom: 0;
	}

	.content-wrapper-center {
		margin-top: 0;
		margin-bottom: 10px;
		padding: 10px 10px 20px;
	}

	.text-span {
		padding-top: 0;
		padding-bottom: 10px;
	}

	.text-span-2 {
		margin-top: 0;
		padding-bottom: 0;
	}

	.text-span-4 {
		margin-bottom: 10px;
	}

	.text-span-5,
	.text-span-5.last-text {
		margin-top: 0;
	}

	.div-block-120 {
		justify-content: center;
		margin-left: 0%;
		padding-left: 0%;
	}

	.dividel-footer {
		width: 30%;
		height: 20px;
		justify-content: center;
		margin-left: 0%;
		margin-right: 0%;
		padding-left: 0%;
		padding-right: 0%;
	}

	.large-card-section {
		grid-template-columns: 1fr 1fr;
		margin-bottom: 20px;
		padding-top: 5%;
		display: block;
	}

	.card-wrapper {
		width: auto;
		margin: 1.5rem 0 0.5rem;
	}

	.overflow-wrapper {
		width: 80%;
	}

	.large-card-background {
		width: 18rem;
		height: 18rem;
	}

	.large-card-text-wrap {
		margin-right: 20px;
	}

	.large-card-header {
		font-size: 1.5rem;
	}

	.card-subtext {
		font-size: 0.6rem;
	}

	.large-card-background-3,
	.large-card-background-2 {
		width: 18rem;
		height: 18rem;
	}

	.link-block-4 {
		margin-top: 0;
		margin-bottom: 0;
	}

	.services-2 {
		height: 73vh;
	}

	.studio-loft {
		max-height: 150px;
	}

	.text-block-6 {
		font-size: 72px;
		bottom: -9%;
	}

	.text-block-31 {
		font-size: 16px;
	}

	.loft-junghwa-dong,
	.poblenou-loft,
	.gym-loft {
		max-height: 150px;
	}

	.div-block-13 {
		height: 100%;
	}

	.tribeca-loft {
		max-height: 150px;
	}

	.grid-5 {
		height: 60%;
	}

	.candy-loft {
		max-height: 150px;
	}

	.div-block-123 {
		width: 100%;
		height: 100%;
	}

	.heading-wrapper-2 {
		margin-bottom: 0;
	}

	.brochure-promo {
		justify-content: center;
		align-items: center;
		margin-left: 0;
		margin-right: 0;
		display: none;
	}

	.promo-container {
		display: block;
	}

	.div-block-124 {
		width: 90%;
		justify-content: center;
		margin-left: 15px;
	}

	.image-28 {
		bottom: 72px;
		left: 96px;
	}

	.icon-wrap-copy {
		width: 5.7rem;
		height: 4.7rem;
		width: 5.7px;
		height: 4.7px;
		width: 5.7rem;
		height: 4.7rem;
		align-items: flex-end;
		display: none;
	}

	.link-text-2 {
		white-space: nowrap;
		flex: 0 auto;
	}

	.content-wrap-stats-copy {
		padding-left: 0;
	}

	.left-arrow-5,
	.right-arrow-5 {
		display: none;
	}

	.div-block-126 {
		padding-left: 20px;
	}

	.slider-learn-more-copy {
		align-items: flex-start;
		margin-left: 0;
		margin-right: 20px;
	}

	.div-block-23-copy {
		background-color: rgba(0, 0, 0, 0);
		margin-top: auto;
		padding: 0 20px;
	}

	.slider-graphic-copy {
		height: auto;
		background-image: none;
		background-repeat: repeat;
		background-size: auto;
		grid-template-rows: 1fr 1fr;
		grid-template-columns: 1fr;
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.heading-4-copy {
		margin-top: 164px;
		padding-top: 0;
		font-size: 28px;
		line-height: 25px;
	}

	.account-login-wrapper-copy {
		z-index: 10;
		width: 100%;
		height: 45%;
		grid-column-gap: 2px;
		grid-row-gap: 2px;
		opacity: 1;
		background-color: rgba(33, 48, 100, 0.05);
		background-image: linear-gradient(rgba(33, 48, 100, 0.78), rgba(33, 48, 100, 0.78));
		justify-content: flex-end;
		align-items: flex-start;
		margin: 20px 0 60px 10px;
		padding-top: 10px;
		padding-bottom: 10px;
		line-height: 20px;
		display: none;
		top: auto;
		bottom: 0%;
		left: auto;
		right: 0%;
		overflow: hidden;
	}

	.online-banking-heading-copy {
		margin-bottom: 5px;
		font-size: 18px;
		line-height: 25px;
	}

	.slide-arrow {
		font-size: 21px;
	}

	.text-block-5-copy {
		font-size: 13px;
	}

	.error-message-copy {
		float: none;
	}

	.online-banking-form-copy {
		height: auto;
		display: block;
	}

	.login-button {
		text-align: center;
		justify-content: flex-start;
		padding-left: 30px;
		padding-right: 30px;
		font-size: 12px;
	}

	.div-block-26 {
		width: 100%;
		flex-direction: row;
	}

	.right-arrow-copy {
		z-index: 30;
		width: 40px;
		height: 50px;
	}

	.slide-nav-copy {
		font-size: 12px;
		top: auto;
		bottom: 0%;
		left: 0%;
		right: 0%;
	}

	.text-block-11 {
		text-align: left;
	}

	.slide-copy {
		background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), none;
		background-position: 0 0, 55%;
		background-repeat: repeat, no-repeat;
		background-size: auto, cover;
		background-attachment: scroll, fixed;
		padding-top: 140px;
	}

	.slider-2-copy-copy {
		width: auto;
		height: auto;
		max-height: 530px;
		max-width: 100%;
		background-color: #213064;
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;
	}

	.slide-link.enroll {
		text-align: left;
		display: block;
	}

	.left-arrow-copy {
		z-index: 30;
		width: 40px;
		display: none;
	}

	.slide-butotn,
	.slide-butotn-copy {
		padding-top: 10px;
	}

	.slide-2-copy {
		background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), none;
		background-position: 0 0, 40%;
		background-repeat: repeat, no-repeat;
		background-size: auto, cover;
		padding-top: 140px;
	}

	.mask-3-copy {
		width: auto;
		height: auto;
		overflow: hidden;
	}

	.gdrp-link-copy {
		margin-right: 0;
		font-size: 12px;
	}

	.home-content-footer {
		background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('/20181016082423/assets/images/background-pattern.jpg');
		background-position: 0 0, 50% 0;
		background-repeat: repeat, repeat-y;
		background-size: auto, 2000px;
		background-attachment: scroll, scroll;
	}

	.exit-icon-div {
		width: 20px;
		height: 20px;
	}

	.cookie-div {
		width: 100%;
		min-width: 100%;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.container-6 {
		padding-left: 0;
		padding-right: 0;
	}

	.container-6.footer {
		flex-direction: column;
		align-items: center;
	}

	.container-6.footer.gdrp {
		flex: 0 auto;
		align-items: center;
		padding-top: 3rem;
		padding-left: 0;
		padding-right: 0;
	}

	.cookie-text-para {
		margin-bottom: 0.5rem;
		padding-left: 0;
		padding-right: 0;
		font-size: 0.612rem;
		line-height: 1.075rem;
	}

	.open-gdrp-icon {
		margin-right: 0.5rem;
	}

	.gdrp-section-icon.just-icon {
		width: 90px;
		justify-content: center;
	}

	.gdrp-link {
		margin-right: 0;
		font-size: 12px;
	}

	.button-underline {
		align-items: flex-start;
		margin-left: 0;
	}

	.button-underline.gdpr {
		margin-top: 0;
	}

	.gdrp-section {
		display: none;
	}

	.promo-counter-copy {
		margin-top: 73px;
	}

	.heading-2 {
		margin-top: 20px;
		margin-left: 0;
		margin-right: 20px;
		font-size: 30px;
		line-height: 25px;
	}

	.div-block-2 {
		width: 100%;
		margin-bottom: 20px;
	}

	.news-section {
		margin-top: 0;
		padding: 0;
	}

	.div-block-12 {
		width: 90%;
		grid-template-columns: 1fr;
	}

	.div-block-5 {
		border-radius: 0;
	}

	.div-block-6 {
		margin-left: 20px;
		margin-right: 20px;
	}

	.image-3 {
		border-radius: 0;
	}

	.text-block-3 {
		margin-left: 20px;
		margin-right: 20px;
	}

	.footer-2 {
		z-index: 0;
		margin-top: 10px;
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;
		top: 0;
	}

	.padding-bottom {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.footer-social-icon-link-2.last-social {
		margin-right: 0;
	}

	.link-list-title-2 {
		justify-content: center;
	}

	.brand-logo-footer {
		margin-top: 10px;
		padding-top: 0;
	}

	.text-block-8-copy {
		width: auto;
		text-align: center;
		width: auto;
		text-align: center;
		font-size: 13px;
	}

	.text-block-34 {
		padding-bottom: 10px;
	}

	.text-block-35 {
		margin-top: 0;
		margin-bottom: 5px;
	}

	.text-block-35.stay {
		margin-top: 10px;
		margin-bottom: 5px;
	}

	.text-block-36 {
		text-align: center;
	}

	.div-block-17-copy {
		align-items: center;
		padding-left: 0;
	}

	.slide-nav-4 {
		bottom: -5px;
	}

	.uui-contact02_component {
		margin-top: 0;
	}

	.form-radio-2col {
		grid-template-columns: 1fr;
	}

	.uui-padding-vertical-xhuge-7 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.form-field-2col {
		grid-template-rows: auto auto;
		grid-template-columns: 1fr;
	}

	.team_item_promo {
		width: 100%;
	}

	.two-box-promo {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		display: grid;
	}

	.team_image {
		height: 14rem;
	}

	.heading-13 {
		font-size: 25px;
		line-height: 30px;
	}

	.team_content-2 {
		right: 10%;
	}

	.button-with-arrow-services {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.slide-3-b {
		background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/20181016082423/assets/images/management1.jpg');
		background-position: 0 0, 44%;
		background-size: auto, cover;
	}

	.lynx-block-hero-2-copy {
		width: 90%;
		text-align: center;
		border-bottom-right-radius: 30px;
		justify-content: center;
		align-items: center;
		margin-top: 0;
		margin-left: 0;
		position: static;
		top: 0;
	}

	.icon-link-wrapper-copy {
		width: auto;
	}

	.mask-6 {
		height: 700px;
	}

	.footer-signoff-links-copy {
		margin-right: 10px;
	}

	.footer-signoff-links-copy.last {
		display: none;
	}

	.text-block-36-copy {
		text-align: center;
	}

	.gdrp-section-2 {
		display: none;
	}

	.gdrp-link-copy-2 {
		margin-right: 0;
		font-size: 12px;
	}

	.open-gdrp-icon-2 {
		margin-right: 0;
	}

	.home-content-footer-2 {
		background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('/20181016082423/assets/images/background-pattern.jpg');
		background-position: 0 0, 50% 0;
		background-repeat: repeat, repeat-y;
		background-size: auto, 2000px;
		background-attachment: scroll, scroll;
	}

	.cookie-text-para-2 {
		margin-bottom: 0.5rem;
		padding-left: 0;
		padding-right: 0;
		font-size: 0.612rem;
		line-height: 1.075rem;
	}

	.gdrp-link-2 {
		margin-right: 0;
		font-size: 12px;
	}

	.enquire-now-footer {
		width: 90px;
		justify-content: center;
		margin-bottom: 20px;
		bottom: 90px;
	}

	.link-block-5 {
		margin-right: 0;
	}

	.heading-16 {
		line-height: 30px;
	}

	.main-content-wrapper.no-sidebars {
		margin-left: 0;
		margin-right: 0;
	}

	.inside-content-column {
		margin-right: 0;
	}

	.heading-17 {
		margin-top: 10px;
	}

	.button-6 {
		margin-bottom: 1rem;
	}

	.inside-content-wrapper-2 {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		display: grid;
	}

	.heading-4 {
		font-size: 24px;
	}

	.right-col {
		width: 80%;
	}

	.slide-3-copy {
		background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/20181016082423/assets/images/management1.jpg');
		background-position: 0 0, 46%;
		background-repeat: repeat, no-repeat;
		background-size: auto, cover;
		background-attachment: scroll, scroll;
	}

	.about-us-copy {
		margin-top: 20px;
	}

	.div-block-17-contact {
		width: 80%;
		border-bottom: 1px solid #047dbf;
		align-items: center;
		padding-bottom: 10px;
		padding-left: 0;
	}

	.div-block-17-contact.last {
		border-bottom-width: 0;
	}

	.div-block-17-copy {
		width: 100%;
		border-bottom: 1px solid #047dbf;
		align-items: center;
		padding-bottom: 0;
		padding-left: 0;
	}

	.mobile-quick-links-wrapper {
		z-index: 100;
		height: auto;
		flex-direction: row;
		margin-top: 0;
		padding-left: 0%;
		display: block;
		position: absolute;
		top: auto;
		bottom: 0%;
		left: auto;
		right: auto;
	}

	.div-block-131 {
		width: 25px;
		height: 25px;
		background-image: url('/20181016082423/assets/images/slider-arrow-new-blue-2.svg');
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: auto;
		padding-bottom: 0;
		padding-right: 0;
	}

	.div-block-131:hover {
		background-image: url('/20181016082423/assets/images/slider-arrow-new-orange.svg');
	}
}

#w-node-badb845f-bf59-ad36-6343-301bea5d9cad-7f62e962,
#w-node-f2ca67d5-b4b6-94c6-61d8-3dd8293d9c2c-7f62e962 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	align-self: center;
	justify-self: center;
}

#w-node-_0a52b94d-a85b-77e9-d813-f5c4cf2d08aa-7f62e962 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	align-self: center;
	justify-self: start;
}

#w-node-_77da7b45-3cbb-224c-2fc9-4422979e8c7a-7f62e962 {
	align-self: center;
	justify-self: start;
}

#w-node-_0a52b94d-a85b-77e9-d813-f5c4cf2d08ad-7f62e962 {
	grid-area: span 2 / span 2 / span 2 / span 2;
	align-self: center;
	justify-self: start;
}

#w-node-_0a52b94d-a85b-77e9-d813-f5c4cf2d08b2-7f62e962 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-ff09f763-a099-374c-a9f3-d6a789700836-7f62e962 {
	align-self: center;
}

#w-node-_563d22e9-adb1-b854-5cdb-7d9da5d89dd2-7f62e962 {
	grid-area: span 2 / span 2 / span 2 / span 2;
	align-self: center;
	justify-self: start;
}

#w-node-_0a52b94d-a85b-77e9-d813-f5c4cf2d08ba-7f62e962 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8956488e-30ee-609b-ff4a-d67e53c61197-7f62e962 {
	align-self: center;
}

#w-node-d0a78e3c-0a03-e544-6f34-25541f5cf346-7f62e962 {
	grid-area: span 2 / span 2 / span 2 / span 2;
	align-self: center;
	justify-self: start;
}

#w-node-_78617290-88e5-12a8-7c0d-4c52894489c2-7f62e962 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	align-self: center;
	justify-self: start;
}

#w-node-_78617290-88e5-12a8-7c0d-4c52894489c3-7f62e962 {
	align-self: center;
	justify-self: start;
}

#w-node-_78617290-88e5-12a8-7c0d-4c52894489c7-7f62e962 {
	grid-area: span 2 / span 2 / span 2 / span 2;
	align-self: center;
	justify-self: start;
}

#w-node-_78617290-88e5-12a8-7c0d-4c52894489ca-7f62e962 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_78617290-88e5-12a8-7c0d-4c52894489cb-7f62e962 {
	align-self: center;
}

#w-node-_78617290-88e5-12a8-7c0d-4c52894489cf-7f62e962 {
	grid-area: span 2 / span 2 / span 2 / span 2;
	align-self: center;
	justify-self: start;
}

#w-node-_78617290-88e5-12a8-7c0d-4c52894489d2-7f62e962 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_78617290-88e5-12a8-7c0d-4c52894489d3-7f62e962 {
	align-self: center;
}

#w-node-_78617290-88e5-12a8-7c0d-4c52894489d7-7f62e962 {
	grid-area: span 2 / span 2 / span 2 / span 2;
	align-self: center;
	justify-self: start;
}

#w-node-_13066274-a8ff-ec93-8807-34996ae8f83c-7f62e962 {
	align-self: center;
}

#w-node-ab4293ab-d78d-a539-e3d3-bb985348137f-7f62e962 {
	grid-area: span 1 / span 3 / span 1 / span 3;
}

#w-node-_5468dbdf-3b9c-a8c0-e38d-8bd7e464e63f-7f62e962 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5468dbdf-3b9c-a8c0-e38d-8bd7e464e640-7f62e962 {
	justify-self: center;
}

#w-node-f7ff7d51-dd52-b43d-95d1-320d6946a5a2-6946a58f,
#w-node-f7ff7d51-dd52-b43d-95d1-320d6946a5c9-6946a58f {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (max-width: 767px) {
	#w-node-badb845f-bf59-ad36-6343-301bea5d9c9a-7f62e962,
	#w-node-f2ca67d5-b4b6-94c6-61d8-3dd8293d9c21-7f62e962 {
		grid-area: span 1 / span 1 / span 1 / span 1;
		justify-self: center;
	}
}

@media screen and (max-width: 479px) {
	#w-node-f35cf7fb-ff47-7577-5b45-61d58fa584de-7f62e962 {
		grid-area: span 1 / span 1 / span 1 / span 1;
	}

	#w-node-c2ca0b8b-7f12-3f77-ca66-30ed341d6f7c-341d6f79,
	#w-node-_944a3f11-d9ab-b5b1-81af-13d382d26fa5-82d26f9c,
	#w-node-b39adab1-89db-3dc2-555f-fede8633b4ac-82d26f9c,
	#w-node-_1e157980-739b-6684-0406-f5d1dd571c4b-82d26f9c,
	#w-node-_3d50b503-6b65-f3c8-ba0a-248683cb8df3-82d26f9c {
		justify-self: center;
	}
}

/******************** Webflow ***********************/
/****************************************************/
.w-slider-dot {
	background-color: #f2f4f7;
	width: 0.625rem;
	height: 0.625rem;
}

/* Change color of the active dot by replacing the hex code*/
.w-slider-dot.w-active {
	background-color: #6941c6;
	width: 0.625rem;
	height: 0.625rem;
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.mega-menu {
	z-index: 1000 !important;
}

#page.show-alert {
	z-index: 10000;
}

.mega-menu {
	transition: background-color 500ms ease;
}

.mega-menu.sticky {
	background-color: rgb(4, 125, 191);
}

/****************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	display: flex;
	grid-column-gap: 0;
	justify-content: flex-start;
}

.accesible-navigation-menu ul li.uui-navbar02_dropdown-toggle-2 {
	padding-right: 26px;
	padding-left: 26px;
}

.accesible-navigation-menu ul li.uui-navbar02_dropdown-toggle-2:last-child {
	padding-right: 0px;
	padding-left: 11px;
}

.accesible-navigation-menu ul li.uui-navbar02_dropdown-toggle-2:last-child a.active,
.accesible-navigation-menu ul li.uui-navbar02_dropdown-toggle-2:last-child a {
	width: 150px;
	height: 50px;
	color: #fff;
	text-transform: uppercase;
	background-color: #faa831;
	border: 1px solid #faa831;
	justify-content: center;
	align-items: center;
	margin-top: 0;
	margin-bottom: 0;
	padding: 1.75rem 1rem;
	font-family: minerva-modern, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #fff;
	transition: all 0.3s;
}

.accesible-navigation-menu ul li.uui-navbar02_dropdown-toggle-2:last-child a:hover {
	color: #faa831;
	background-color: #fff;
}

.accesible-navigation-menu ul li a.nav-link {
	text-decoration: none;
	padding-right: 0px;
}


.accesible-navigation-menu ul li a.nav-link.active {
	color: #faa831;
}

.accesible-navigation-menu ul li.hasChildren a.nav-link:after {
	content: "\e603";
	font-family: webflow-icons !important;
	color: #fff;
	display: block;
	margin-left: 15px;
	font-size: 15px;
	transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	transform-style: preserve-3d;
	transition: all 400ms ease;
}

.accesible-navigation-menu ul li.hasChildren:hover a.nav-link:after {
	transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg);
}

.accesible-navigation-menu li.hasChildren .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: auto;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
	width: 100%;
	padding-left: 0px;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul {
	display: none;
	background-color: #047dbf;
	padding: 0;
	margin: 0px;
	list-style-type: none;
	width: 100%;
	margin-top: 24px;
	min-width: 200px;
	padding-top: 0.5rem;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul li {
	display: flex;
	width: 100%;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul a {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: uppercase;
	margin-bottom: 0.25rem;
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	margin-bottom: 0.25rem;
	font-size: 14px;
	line-height: 20px;
	display: inline-block;
	width: 100%;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	word-wrap: break-word;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul a:hover,
.accesible-navigation-menu li.hasChildren .container-wrapper ul a.w--current {
	color: #faa831;
}

.accesible-navigation-menu li.hasChildren.open .container-wrapper,
.accesible-navigation-menu li.hasChildren:hover .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.hasChildren.open .container-wrapper ul,
.accesible-navigation-menu li.hasChildren:hover .container-wrapper ul {
	display: inline-block;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
.mobile-navigation-menu {
	display: none;
	margin-top: 100px;
	height: calc(100% - 100px);
}

@media (min-width: 992px) {
	.mobile-navigation-menu,
	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu li[data-breakpoints="xl"] {
	display: flex !important;
}

.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
	display: flex !important;
}

.mobile-navigation-menu li[data-breakpoints="xs,sm,md,lg,xl"] {
	display: flex !important;
}

@media (max-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints="xl"] {
		display: none !important;
	}

	.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
		display: flex !important;
	}
}

@media (max-width: 479px) {
	.mobile-navigation-menu li[data-breakpoints="xl"] {
		display: none !important;
	}

	.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
		display: none !important;
	}

	.mobile-navigation-menu {
		margin-top: 80px;
		height: calc(100% - 80px);
	}
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mm-panels > .mm-panel,
.mm-navbar {
	background-color: #fff;
}

.mobile-navigation-menu li a.nav-a,
.mobile-navigation-menu li span.mm-btn {
	color: #101828;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
	font-family: minerva-modern, sans-serif;
	text-transform: uppercase;
	font-size: 1rem;
	padding: 15px 20px;
}

.mobile-navigation-menu li a.nav-a:hover,
.mobile-navigation-menu li a.nav-a.active,
.mobile-navigation-menu li span.mm-btn:hover,
.mobile-navigation-menu li span.mm-btn.active {
	color: #faa831;
}

.mm-listitem:after {
	border-bottom: 0px;
}

.mm-menu a,
.mm-menu a:active,
.mm-menu a:hover,
.mm-menu a:link,
.mm-menu a:visited {
	border: 0px;
}

.mm-listitem_opened > .mm-listitem__btn,
.mm-listitem_opened > .mm-panel {
	background-color: transparent;
}

.mm-listitem_opened > .mm-listitem__btn,
.mm-listitem_opened > .mm-panel {
	padding-top: 0px;
}

.mobile-navigation-menu li a.nav-a.yellow-button.active,
.mobile-navigation-menu li a.nav-a.yellow-button {
	background-color: #faa831;
	text-align: center;
	margin-left: 10px;
	margin-right: 10px;
	border: 1px solid #faa831;
	color: #101828;
}

.mobile-navigation-menu li a.nav-a.yellow-button:hover {
	background-color: #fff;
	color: #101828;
}

/********************** Popup Alert *****************/
/*****************************************************/
.modal-alert {
	display: none;
	position: fixed;
	z-index: 100000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);
}

.modal-alert .modal-content-alert {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	justify-content: center;
}

.modal-alert .modal-content-alert-inner {
	background-color: #fefefe;
	padding: 30px;
	width: 80%;
	max-width: 500px;
	font-family: Heebo, sans-serif;
	font-size: 1rem;
	line-height: 24px;
}

.modal-alert .close {
	color: #aaa;
	float: right;
	font-size: 45px;
	font-weight: bold;
	margin-bottom: 10px;
}

.modal-alert .close:hover,
.modal-alert .close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

.modal-alert .cms-website-content-region {
	clear: both;
}

/********************* Homepage *********************/
/*****************************************************/
.cbtext p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit !important;
}

.testimonias-one-by-one .more-button {
	margin-top: 30px;
}

@media screen and (min-width: 1920px) {
	.icon-link-wrapper {
		justify-content: flex-end;
		padding-bottom: 55px;
	}
}

@media (max-width: 1921px) and (min-width: 992px) {
	.icon-link-wrapper {
		justify-content: flex-end;
		padding-bottom: 32px;
	}
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-text p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit;
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube.com"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumb-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumb-list li:last-child a:hover,
.breadcrumb-list li:last-child a {
	text-decoration: none;
	color: #313131;
	border-bottom: 1px solid transparent;
}

.left-nav-list-link-2.active {
	color: #2a4187;
	background-image: none;
	background-size: auto;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.left-nav-nested-list-link-2.active {
	color: #2a4187;
	background-image: url('/20181016082423/assets/images/angle-right_6f6e6d.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 6px;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

@media screen and (max-width: 991px) {
	.inside-content-wrapper-2 {
		display: block;
	}

	.right-col {
		width: 100%;
		margin-top: 40px;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-col td img {
	max-width: none;
}

.right-col .box {
	border-top: 3px solid #047dbf;
	margin-bottom: 1.5rem;
	margin-left: 0;
	padding-top: 0.75rem;
	font-size: 14px;
	line-height: 20px;
	color: #333;
}

.right-col .box p {
	font-size: 14px;
	line-height: 20px;
	color: #333;
}

.right-col .box h4,
.right-col .box h3 {
	margin-top: 0;
	margin-bottom: 0.2rem;
	padding-top: 0.2rem;
	padding-bottom: 0.6rem;
	color: #047dbf;
	background-color: #fff;
	margin-top: 0.2rem;
	font-family: minerva-modern, sans-serif;
	font-size: 26px;
	font-weight: bold;
}

@media screen and (max-width: 991px) {
	.right-col .box h4,
	.right-col .box h3 {
		font-size: 22px;
		line-height: 24px;
	}
}

.right-col .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-col .box .contentbox_item_image {
	width: 24px;
}

.right-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-col .box.tools td:first-child img {
	margin-right: 5px;
}

.right-col a {
	border-bottom: 1px solid #797676;
	font-weight: 400;
	transition-duration: 0.2s;
	color: #313131;
	padding-bottom: 0px;
}

.right-col .content a {
	text-decoration: none;
}

.right-col .content > a {
	border-bottom: 0px;
	text-decoration: underline;
}

.right-col a:hover {
	color: #2a4187;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 991px) {
	.right-col .box {
		margin-left: 0;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.left-sidebar a,
.inside-content-column a,
.cbtext a {
	color: #313131;
	border-bottom: 0 solid #797676;
	line-height: 1rem;
	transition-property: none;
}

.left-sidebar a:hover,
.inside-content-column a:hover,
.cbtext a:hover {
	color: #2a4187;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

h1 {
	color: #047dbf;
	text-transform: uppercase;
	font-family: minerva-modern, sans-serif;
	font-size: 50px;
	font-weight: 400;
	line-height: 4rem;
}

h2 {
	color: #047dbf;
	text-transform: uppercase;
	margin-top: 10px;
	font-family: minerva-modern, sans-serif;
	font-size: 50px;
	font-weight: 400;
	line-height: 50px;
}

h3 {
	color: #047dbf;
	font-family: minerva-modern, sans-serif;
	font-size: 26px;
	line-height: 30px;
}

h4 {
	color: #047dbf;
	font-family: minerva-modern, sans-serif;
	font-weight: 400;
}

h5 {
	color: #047dbf;
	letter-spacing: 0;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 20px;
	font-family: minerva-modern, sans-serif;
	font-size: 14px;
	font-weight: 700;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a {
	color: #047dbf !important;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover {
	color: #2a4187 !important;
}

h6 a {
	color: #313131 !important;
}

h6 a:hover {
	color: #2a4187 !important;
}

p {
	font-size: 1.125rem;
	font-family: Heebo, sans-serif;
}


@media (max-width: 991px) {
	h1 {
		font-size: 38px;
	}

	h2 {
		font-size: 26px;
		line-height: 33px;
	}

	h3 {
		font-size: 22px;
		line-height: 24px;
	}

	h4 {
		font-size: 20px;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 32px;
		line-height: 2.5rem;
	}
}

@media (max-width: 479px) {
	h1 {
		font-size: 28px;
		line-height: 1.8rem;
	}

	h2 {
		font-size: 30px;
		line-height: 30px;
	}

	h4 {
		font-size: 24px;
	}
}

h2 a {
	line-height: inherit !important;
}

/* buttons */
.inside-row .button,
.inside-row a.primary,
.homeboxes a.primary,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	color: #fff !important;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	cursor: pointer;
	background-color: #2a4187 !important;
	background-image: url('/20181016082423/assets/images/white-arrow-icon-01.svg');
	background-position: calc(100% - 20px) center;
	background-repeat: no-repeat;
	background-size: 20px;
	border-radius: 0;
	padding: 0.75rem 50px 0.75rem 1.25rem;
	font-size: 0.85rem;
	font-weight: 500;
	transition: all 0.4s, background-color 0.4s;
	text-decoration: none;
	border: 0px;
	font-family: Heebo, sans-serif;
}

.inside-row .button:hover,
.inside-row a.primary:hover,
.homeboxes a.primary:hover,
.primary:hover,
.inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	background-color: #047dbf !important;
	color: #fff !important;
}

.secondary,
.inside-row a.secondary,
.homeboxes a.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	display: inline-block;
	border: 0;
	line-height: inherit;
	text-decoration: none;
	cursor: pointer;
	border-radius: 0;
	color: #047dbf !important;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0) !important;
	padding: 0;
	font-weight: 600;
	transition: all 0.45s;
	font-family: Heebo, sans-serif;
}

.secondary:hover,
.inside-row a.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	background-color: rgba(0, 0, 0, 0) !important;
	color: #2a4187 !important;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	border: 0;
	line-height: inherit;
	text-decoration: none;
	cursor: pointer;
	border-radius: 0;
	color: #047dbf !important;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0) !important;
	padding: 0;
	font-weight: 600;
	font-family: Heebo, sans-serif;
	transition: all 0.45s;
}

.tertiary:hover,
.inside-row a.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	background-color: rgba(0, 0, 0, 0) !important;
	color: #2a4187 !important;
}

/* messages */
#message.success {
	color: #000;
	background-color: #caf3aa;
	border: 2px solid #48a700;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
}

#message.error {
	color: #000;
	background-color: #ffccd0;
	border: 2px solid #721926;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
	font-weight: 400;
}

#message.success p {
	color: #000;
	font-weight: 400;
}

#message.error p {
	color: #000;
	font-weight: 400;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid rgba(0, 0, 0, 0.15);
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: 400;
	text-transform: none;
	letter-spacing: 0.02em;
	color: white;
	background-color: #053061;
	border-bottom: 1px solid white;
	padding: 0.875em 0.625rem;
	font-size: 1.125rem;
}

table.styled th {
	font-weight: 300;
	padding: 0.625em 0.625rem;
	background-color: #047dbf;
	border-bottom: 0px solid white;
	color: white;
	font-size: 1.125rem;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
	transition: none;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 1rem;
	line-height: 1.4125rem;
	color: #333;
}

table.styled.striped tr:nth-child(even) {
	background: #eee;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea,
.inside-row input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #666666;
	font-size: 0.875rem;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea,
	.inside-row input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	background-color: rgba(226, 235, 240, 0.1);
	border-left-color: #faa831;
}

blockquote p {
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}


/*pagination*/
.pagination-wrapper {
	font-size: 0.875em;
	line-height: 1.25em;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	border-bottom: 1px solid transparent;
	font-style: normal;
}

.inside-page-content-wrap-no-sidebars-2 ul {
	list-style: outside disc;
	margin-left: 1em;
	padding-left: 0;
}

.inside-page-content-wrap-no-sidebars-2 ul li {
	position: relative;
	left: 1.5rem;
	padding-right: 1.5rem;
}

/*********************** Footer **********************/
/*****************************************************/
#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
	float: right;
}

.phone-mobile {
	display: none;
	color: #1d1d1b;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.phone-desktop {
	display: inline-block;
}

.footer-links-list {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

.footer-links-list li {
	margin: 0px;
	padding: 0px;
}

@media (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
	}
}

@media (max-width: 479px) {
	#GRIPFooterLogo {
		margin-bottom: 10px;
	}

	.footer-links-list {
		text-align: center;
	}
}

/****************** Content Modules ****************/
/***************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

.ui-element-with-example .source-code {
	overflow: hidden;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

#ui-datepicker-div {
	font-size: 0.9rem;
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* submission forms */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .cms_buttons {
	margin-left: calc(33% + 10px);
}

/* services */
#services_module.list .list_item .title h3 {
	margin-top: 0px;
}

#services_module.entity .image {
	float: none;
	margin: 0px 0px 10px 0px;
}

#services_module.list .list_item .footer {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	padding-bottom: 0px;
	margin-top: 0px;
}

#services_module.list .list_item {
	margin-bottom: 1.5rem;
	margin-top: 0.5rem;
}

/* faqs */
#faqs_module h2 {
	font-size: 35px;
	line-height: 38px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
	margin-bottom: 5px;
	font-size: 1rem;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: rgba(92, 92, 92, 0.15);
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: bold;
}

#jobs_module.cms_entity h2 {
	font-size: 35px;
	margin-top: 20px;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.75rem 3.75rem 0.75rem 1.25rem;
	margin-top: 10px;
	margin-left: 0px;
	margin-right: 0px;
}

@media (min-width: 992px) {
	#jobs_module.cms_form #frmJobSubscription p {
		width: 65%;
		text-align: left;
		float: right;
		padding-left: 5px;
	}
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/* news */
#news_module.cms_list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

#news_module.cms_list article a,
#news_module.cms_list article time {
	font-size: 1rem;
}

#news_module.cms_list article h2 a,
#news_module.cms_list h2 {
	font-size: 28px;
	line-height: 33px;
	text-transform: none;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

#news_module.cms_list article .description,
#news_module.cms_list article .description p {
	font-size: 1rem;
}

#news_module.cms_list .cms_item_divider {
	display: none;
}

#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list .description {
	word-wrap: break-word;
}

#news_module.cms_list .cms_categories_section {
	margin-top: 20px;
	width: 100%;
}

#news_module.cms_list .cms_categories_section .cms_category_item {
	display: flex;
	flex-direction: column;
}

#news_module.cms_list .cms_categories_section .cms_category_item .image {
	order: 1;
}

#news_module.cms_list .cms_categories_section .cms_category_item .cms_title {
	order: 2;
}

#news_module.cms_list .cms_categories_section .cms_category_item .abstract {
	order: 3;
}

#news_module.cms_list .cms_categories_section .cms_category_item .cms_title h3 {
	margin: 0px;
}

#news_module.cms_list .cms_categories_section .cms_category_item {
	margin-bottom: 30px;
}

#news_module.cms_list article {
	width: 46%;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
}

#news_module.cms_list article .news-thumbnail-image {
	order: 1;
	margin-bottom: 5px;
	text-align: left;
	display: flex;
	aspect-ratio: 16 / 9;
	align-items: center;
	overflow: hidden;
	max-width: 400px;
}

#news_module.cms_list .cms_categories_section .cms_category_item .image {
	aspect-ratio: 16 / 9;
	overflow: hidden;
	max-width: 400px;
}


#news_module.cms_list .cms_categories_section .cms_category_item .image img,
#news_module.cms_list article .news-thumbnail-image img {
	max-height: 100%;
	object-fit: cover;
	transition: 0.5s all ease;
	width: 100%;
	height: 100%;
}

#news_module.cms_list article .news-thumbnail-image:hover img,
#news_module.cms_list .cms_categories_section .cms_category_item .image:hover img {
	transform: scale(1.2);
}

#news_module.cms_list article time {
	order: 2;
	font-weight: normal;
}

#news_module.cms_list article > h2 {
	order: 3;
}

#news_module.cms_list article .description {
	order: 4;
}

#news_module.cms_list article > a {
	order: 5;
	font-weight: bold;
	flex-grow: 1;
	display: flex;
	align-items: self-end;
}

@media screen and (max-width: 991px) {
	#news_module.cms_list .cms_categories_section .cms_category_item .image {
		width: auto;
	}
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}

	#news_module.cms_list .cms_categories_section .cms_category_item {
		padding-right: 0px;
	}

	#news_module.cms_list .cms_categories_section .cms_category_item:last-of-type {
		margin-bottom: 30px;
	}

	#news_module.cms_list article {
		width: 100%;
	}
}

/* partners */
#partners_module.cms_list h2 {
	font-size: 35px;
	line-height: 38px;
}

#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* success stories */
#stories_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
	margin-bottom: 0.7rem;
}

#stories_module.cms_list .cms_list_item .image {
	max-width: 200px;
}

#stories_module.cms_list .cms_item_divider,
#stories_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

@media (max-width: 991px) {
	#stories_module.cms_list .cms_list_item .image {
		padding: 0px 0px 10px 0px;
		display: block;
		float: none;
	}
}

/* gdpr */
.body.consent-form-visible .mm-slideout {
	z-index: 10000;
}

#cookie-consent-container {
	color: #fff;
	padding-left: 10px;
	padding-right: 10px;
	font-family: Heebo, sans-serif;
	font-size: 14px;
	line-height: 1.3rem;
	box-shadow: 0 -5px #1d1d1b;
	background-color: #333;
	font-family: Heebo, sans-serif;
}

#cookie-consent-form-container {
	font-family: Heebo, sans-serif;
}

#cookie-consent-container.minimized {
	box-shadow: none;
	background-color: #213a7f;
	bottom: 5rem;
	right: 1.5rem;
}

#cookie-consent-form-container form .consent-header {
	text-align: center;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
}

#cookie-consent-container .consent-actions a.consent-all-trigger,
#cookie-consent-container .consent-actions a.reject-all-trigger {
	color: #fff;
	text-align: center;
	background-color: #047dbf !important;
	margin-right: 10px;
	padding: 10px;
	font-family: Heebo, sans-serif !important;
	font-size: 14px !important;
	font-weight: 400;
	text-decoration: none;
	background-image: none;
	margin-top: 0px;
}

#cookie-consent-container .consent-actions a.consent-all-trigger:hover,
#cookie-consent-container .consent-actions a.reject-all-trigger:hover {
	text-decoration: underline;
}

#cookie-consent-container .consent-actions a {
	color: #fff;
	text-align: center;
	background-color: rgba(0, 0, 0, 0);
	font-family: Heebo, sans-serif;
	font-size: 14px;
	font-weight: 400;
	text-decoration: none;
	border-bottom: 0px;
	margin-top: 0px;
}

#cookie-consent-container .consent-actions a:hover {
	text-decoration: underline;
}

#cookie-consent-container .consent-disclaimer {
	width: 62%;
	padding-left: 20px;
}

#cookie-consent-container .consent-actions {
	width: 38%;
}

#cookie-consent-container .consent-actions .consent-actions-container {
	flex-direction: row;
}

#cookie-consent-container .consent-actions .consent-actions-container {
	align-items: center;
}

#cookie-consent-form-container form {
	top: 3%;
}

@media (max-width: 991px) {
	#cookie-consent-container {
		display: flex;
		flex-direction: column;
	}

	#cookie-consent-container .consent-actions {
		width: 100%;
		order: 2;
	}

	#cookie-consent-container .consent-disclaimer {
		width: 100%;
		padding-left: 5px;
		padding-right: 5px;
	}

	#cookie-consent-container .consent-dismiss-trigger-container {
		position: absolute;
		top: 5px;
		right: 10px;
	}

	#cookie-consent-form-container form {
		top: 4%;
	}
}

@media screen and (max-width: 767px) {
	#cookie-consent-form-container {
		max-height: 100vh;
	}

	#cookie-consent-form-container form {
		top: 20%;
	}
}

@media screen and (max-width: 479px) {
	#cookie-consent-container.minimized {
		bottom: 4.5rem;
		right: 1.3rem;
	}
}

/* testimonials */
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_item_divider,
#testimonials_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}


/****************** Content Boxes ******************/
/***************************************************/
/* submission forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/* testimonials */
.uui-heading-medium-8 .quotes {
	font-size: 25px;
	line-height: 25px;
	margin: 5px;
}

#testimonials_module_contentbox.slideshow .slick-dots {
	bottom: -30px;
}

#testimonials_module_contentbox.slideshow .slick-dots li button:before {
	font-size: 11px;
}

#testimonials_module_contentbox.slideshow .slick-dots li {
	margin: 0px 1px;
}

#testimonials_module_contentbox.slideshow .uui-heading-medium-8.text-weight-medium {
	width: 60%;
	margin: 0 auto;
}

@media screen and (max-width: 991px) {
	#testimonials_module_contentbox.slideshow .slick-dots {
		bottom: -25px;
	}
}

@media screen and (max-width: 767px) {
	#testimonials_module_contentbox.slideshow .uui-heading-medium-8.text-weight-medium {
		width: 70%;
		margin: 0 auto;
	}
}

@media screen and (max-width: 479px) {
	#testimonials_module_contentbox.slideshow .slick-dots {
		bottom: -18px;
	}
}

/* news */
#news_module.homepage_contentbox {
	text-align: left;
}

#news_module.homepage_contentbox .cms_title h3 {
	font-size: 1.1em;
	margin: 0px;
}

#news_module.homepage_contentbox .cms_date h3 {
	font-size: 1em;
	margin: 0px;
}

/* partners */
#partners_module.homepage_contentbox {
	text-align: left;
}


/**************** Services Homeage ******************/
/***************************************************/
.services-section {
	scroll-margin: 50px;
}

.services-section.left-services {
	z-index: 0;
	background-color: #f9f9f9;
	padding-top: 70px;
	padding-bottom: 70px;
	position: relative;
	overflow: hidden;
}

.services-section .container-7 {
	width: 100%;
	max-width: 1360px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 40px;
	padding-right: 40px;
	overflow: hidden;
}

.services-section .expertise-inner-wrap {
	grid-column-gap: 40px;
	grid-row-gap: 12px;
	grid-template: "." / 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.services-section .heading-2 {
	color: #047dbf;
	-webkit-text-stroke-color: #67011f;
	text-transform: uppercase;
	margin-top: 10px;
	font-family: minerva-modern, sans-serif;
	font-size: 40px;
	font-weight: 400;
	line-height: 35px;
}

.services-section .heading-2.insing-title-padding {
	margin-bottom: 20px;
}

.services-section .section-paragraph.mb-60 {
	font-family: Heebo, sans-serif;
	font-size: 1rem;
	line-height: 1.6rem;
}

.services-section .expertise-single-item {
	z-index: 0;
	max-width: 70%;
	justify-content: center;
	align-items: center;
	display: flex;
	position: relative;
	overflow: hidden;
	justify-self: end;
}

.services-section .expertise-single-item img {
	border-radius: 60px;
}

.services-section .inside-content-wrapper-2 {
	min-height: auto;
}

.services-section ul li {
	font-family: Heebo, sans-serif;
	font-size: 1rem;
	line-height: 20px;
}

.services-section h3 {
	font-family: minerva-modern, sans-serif;
	font-weight: 700;
	font-size: 1rem;
	line-height: 1.6rem;
	color: #333;
}

.services-section .we-make-bg-block {
	z-index: -1;
	width: 79%;
	height: 100%;
	background-color: rgba(4, 125, 191, 0.1);
	position: absolute;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: auto;
}

.services-section.right-services {
	padding-top: 90px;
	padding-bottom: 90px;
	position: relative;
}

.services-section .container-8 {
	width: 100%;
	max-width: 1360px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 40px;
	padding-right: 40px;
	overflow: hidden;
}

.services-section .container-8.overflow-visible {
	overflow: visible;
}

.services-section .we-provide-content-wrap {
	z-index: 0;
	grid-column-gap: 40px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	align-items: center;
	display: grid;
	position: relative;
}

.services-section .section-thumbnail-block {
	z-index: 0;
	position: relative;
}

.services-section .self-care-thumbnail-wrap {
	max-width: 70%;
	background-color: #e0edf3;
	border-radius: 60px;
	display: inline-block;
	position: relative;
}

.services-section .self-care-thumbnail-wrap {
	width: 60%;
}

.services-section .overflow-hidden {
	max-width: 100%;
	overflow: hidden;
}

.services-section .image-38 {
	border-radius: 60px;
}

.section-cotent-wrap {
	align-self: center;
}

.services-section .section-paragraph.mb-60 p,
.intro-text-style-2 p {
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
}

.services-section.right-services .self-care-thumbnail-wrap {
	width: auto;
}

.services-section .self-care-bg-block {
	z-index: -1;
	width: 69%;
	height: 100%;
	background-color: rgba(224, 237, 243, 0);
	position: absolute;
	top: 0%;
	bottom: auto;
	left: auto;
	right: 0%;
}

.services-section a.primary {
	padding-top: 8px;
	padding-bottom: 8px;
	border: 1px solid transparent;
}

@media screen and (min-width: 1920px) {
	.services-section .self-care-bg-block {
		width: 63%;
	}
}

@media screen and (min-width: 1440px) {
	.services-section .self-care-bg-block {
		width: 64%;
	}
}

@media screen and (max-width: 991px) {
	.services-section .container-7 {
		padding-left: 20px;
		padding-right: 20px;
	}

	.services-section .expertise-inner-wrap {
		grid-template-columns: 1fr;
	}

	.services-section .heading-2 {
		font-size: 26px;
		line-height: 30px;
	}

	.services-section .we-make-bg-block {
		width: 110%;
	}

	.services-section .container-8 {
		padding-left: 20px;
		padding-right: 20px;
	}

	.services-section .we-provide-content-wrap {
		grid-template-columns: 1fr;
	}

	.services-section .expertise-single-item {
		justify-self: start;
	}

	.services-section .self-care-bg-block {
		z-index: -1;
		width: 100%;
	}
}

@media screen and (max-width: 767px) {
	.services-section .container-7 {
		padding-left: 15px;
		padding-right: 15px;
	}

	.services-section .expertise-inner-wrap {
		display: block;
	}

	.services-section .expertise-single-item {
		display: inline-block;
	}

	.services-section .container-8 {
		padding-left: 15px;
		padding-right: 15px;
	}

	.services-section .we-provide-content-wrap {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 479px) {
	.services-section .expertise-inner-wrap {
		grid-column-gap: 10px;
	}

	.services-section .heading-2 {
		margin-top: 20px;
		margin-left: 0;
		margin-right: 20px;
		font-size: 30px;
		line-height: 25px;
	}

	.services-section .expertise-single-item {
		width: 70%;
		margin-top: 30px;
	}
}
